import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../../components/Table.jsx';
import Button from '../../../components/Button.jsx';
import removeTransition from '../../../functions/removeTransition';
import AnimateChange from '../../../components/AnimateChange.jsx';

class ProfileContentCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentShow: this.stepCounter,
        };

        this.showMore = this.showMore.bind(this);
    }

    stepCounter = 10;

    checkInit() {
        const { privateContent, setUpdateKey } = this.props;

        if (privateContent && !this.isReady) {
            this.isReady = true;

            this.setState(
                {
                    codes: privateContent.codes?.map((code, key) => ({
                        ...code,
                        date: code.registered,
                        id: key,
                    })),
                },
                () => {
                    setUpdateKey();
                },
            );
        }
    }

    getCodes() {
        const { codes = [], currentShow } = this.state;

        return codes.filter((code, key) => key < currentShow);
    }

    checkMore() {
        const { codes = [] } = this.state;

        return codes.length > this.getCodes().length;
    }

    showMore() {
        if (this.checkMore()) {
            this.setState({ currentShow: this.state.currentShow + this.stepCounter }, () => {
                removeTransition({ item: '.table__rowWrapper', isCurrent: true });
            });
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    render() {
        const { setUpdateKey, components } = this.props;
        const codes = this.getCodes();

        return (
            <>
                <div className={`profileContent__pageHead ${!components ? '_hide' : ''}`}>
                    <div className="profileContent__pageTitle">
                        {components?.codes?.header?.title}
                    </div>
                    <div className="profileContent__pageDescription">
                        {components?.codes?.header?.description}
                    </div>
                </div>
                <div className="profileContent__pageContent">
                    <Table
                        name="codes"
                        items={codes}
                        isReady={!!this.state.codes}
                        callback={() => {
                            setUpdateKey();
                        }}
                        emptyTitle="Кодов пока нет"
                    />
                    <AnimateChange
                        className="profileContent__pageButton _center"
                        renderKey={this.checkMore() ? 'more' : 'null'}
                    >
                        {this.checkMore() ? (
                            <>
                                <Button className="_white _bigSize _wide" onClick={this.showMore}>
                                    Показать ещё
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                    </AnimateChange>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentCodes);

ProfileContentCodes.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
    components: PropTypes.object,
};
