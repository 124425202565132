import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../functions/setSpacesInText';

class IndexInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { inProfile, components } = this.props;
        const content = components?.translator;
        const appContent = components?.app;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexInfo ${inProfile ? '_profile' : ''} ${
                        content ? '_ready' : ''
                    }`}
                >
                    <div className="indexInfo__inner">
                        {/* <div className="indexInfo__head">
                            <div className="indexInfo__headTitle">{content?.header?.title}</div>
                        </div>
                        <div className="indexInfo__content">
                            <div className="indexInfo__contentInner">
                                <div
                                    className="indexInfo__contentText"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(content?.header?.description),
                                    }}
                                ></div>
                                <Link
                                    className="indexInfo__contentButton"
                                    href={user ? 'profile/sounds' : 'login'}
                                    callback={() => {
                                        document.dispatchEvent(
                                            new CustomEvent('scrollToSounds', {
                                                detail: { duration: inProfile ? 300 : 0 },
                                            }),
                                        );
                                    }}
                                >
                                    <Button className="_red _2 _bigSize _wide _pulsation">
                                        {content?.button?.title}
                                    </Button>
                                </Link>
                            </div>
                        </div> */}

                        {!inProfile && (
                            <>
                                <div className="indexInfo__decor _1"></div>
                                <div className="indexInfo__decor _2"></div>
                                <div className="indexInfo__decor _3"></div>
                                <div className="indexInfo__decor _4"></div>
                                <div className="indexInfo__decor _5"></div>

                                <div className="indexInfo__head">
                                    <div className="indexInfo__headTitle">
                                        {appContent?.header?.title}
                                    </div>
                                    <div className="indexInfo__headSubTitle">
                                        {appContent?.header?.description}
                                    </div>
                                </div>
                                <div className="indexInfo__app">
                                    <img
                                        src={require('../../media/screen-app-01.webp')}
                                        alt=""
                                        className="indexInfo__appImage"
                                    />
                                    <div className="indexInfo__appContent">
                                        <a
                                            className="indexInfo__appCode"
                                            href={appContent?.qr?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={appContent?.qr?.thumb}
                                                alt=""
                                                className="indexInfo__appCodeImage"
                                            />
                                        </a>
                                        <div className="indexInfo__appBox">
                                            <div
                                                className="indexInfo__appBoxContent"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(appContent?.qr?.title),
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexInfo);

IndexInfo.propTypes = {
    device: PropTypes.string,
    inProfile: PropTypes.bool,
    components: PropTypes.object,
    user: PropTypes.object,
};
