import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

class AboutShops extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkReady() {
        const { wheretobuy } = this.props;

        if (wheretobuy && !this.isReady) {
            this.isReady = true;

            const inner = this.parent.current.querySelector('.aboutShops__cards');

            this.setState({ contentHeight: inner.offsetHeight, isReady: true });

            setTimeout(() => {
                this.setState({ contentHeight: null });
            }, 500);
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { isReady, contentHeight } = this.state;
        const { wheretobuy = [] } = this.props;

        return (
            <>
                <div ref={this.parent} className={`aboutShops ${isReady ? '_ready' : ''}`}>
                    <div className="aboutShops__inner">
                        <div className="aboutShops__title _dark">Где Купить:</div>
                        <div
                            className="aboutShops__content"
                            style={
                                !isReady
                                    ? { height: `60rem` }
                                    : contentHeight
                                    ? { height: `${contentHeight}px` }
                                    : {}
                            }
                        >
                            <Animate className="aboutShops__loader" isShow={!isReady}>
                                <div className="aboutShops__loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="aboutShops__cards">
                                {wheretobuy.map((market, key) => (
                                    <div className="aboutShops__card" key={key}>
                                        <a
                                            href={market.url}
                                            className="aboutShops__market"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={market.thumb}
                                                alt=""
                                                className="aboutShops__marketLogo"
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(AboutShops);

AboutShops.propTypes = {
    device: PropTypes.string,
    wheretobuy: PropTypes.array,
};
