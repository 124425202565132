import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import handlerPopup from '../../functions/handlerPopup';
import changePage from '../../functions/changePage';
import setSpacesInText from '../../functions/setSpacesInText';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { user, components, loades } = this.props;
        const content = components?.anounce;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexHeader ${content ? '_ready' : ''} ${loades[0] ? '_init' : ''}`}
                    id="index"
                >
                    <div className="indexHeader__inner">
                        {loades[1] && <div className="indexHeader__decor _1"></div>}
                        {loades[2] && <div className="indexHeader__decor _2"></div>}
                        {loades[3] && <div className="indexHeader__decor _3"></div>}

                        <div className="indexHeader__content">
                            {loades[4] && (
                                <img
                                    src={require('../../media/data.svg').default}
                                    alt=""
                                    className="indexHeader__test"
                                />
                            )}
                            {loades[5] && (
                                <img
                                    src={require(`../../media/slogan-music.svg`).default}
                                    alt=""
                                    className="indexHeader__slogan"
                                />
                            )}

                            <p
                                className="indexHeader__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(content?.slogan?.title),
                                }}
                            ></p>
                            <div className="indexHeader__button">
                                <Button
                                    className="_red _2 _bigSize _shadow _pulsation"
                                    onClick={() => {
                                        if (user) {
                                            handlerPopup({ name: 'codePopup', isShow: true });
                                        } else {
                                            changePage({ href: 'login' });
                                        }
                                    }}
                                >
                                    зарегистрировать код
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        loades: state.loades,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
    loades: PropTypes.array,
};
