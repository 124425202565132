import React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Link from '../../components/Link.jsx';
import Select from '../../components/Select.jsx';
import Icon from '../../components/Icon.jsx';
import Table from '../../components/Table.jsx';
import Button from '../../components/Button.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import removeTransition from '../../functions/removeTransition';
import getHeaders from '../../functions/getHeaders';
import requestSuccess from '../../functions/requestSuccess';

class WinnersHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: this.stepCounter,
        };

        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerSelect = this.handlerSelect.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 10;

    handlerSearch({ target }) {
        const { value } = target;
        let resultValue = value.replace(/[^\d]/gi, '');

        resultValue = resultValue.slice(0, 4);

        this.setState({ search: resultValue });
    }

    handlerSelect({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else if (value === this.state.currentRaffleId) {
                resolve();
            } else {
                this.setState(
                    {
                        currentRaffleId: value,
                        currentStep: this.stepCounter,
                        search: '',
                    },
                    () => {
                        removeTransition({
                            item: '.table__rowWrapper',
                            isCurrent: true,
                        });

                        resolve();
                    },
                );
            }
        });
    }

    getItems(force) {
        const { currentRaffleId, winners = [], currentStep, search } = this.state;

        if (force === true) {
            return winners.filter((item) => item.raffleId === currentRaffleId);
        }

        const items = winners
            .filter((item) => item.raffleId === currentRaffleId || search)
            .filter(
                (item, key) =>
                    (!search || item.phone.replace(/[^\d]/gi, '').slice(-4).includes(search)) &&
                    (force === false || key < currentStep),
            );

        return items;
    }

    getWinners() {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetWinnerList`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    const { raffles = [], winners = [] } = res.data.data;
                    const resultWinners = winners.map((item, key) => ({
                        ...item,
                        id: key,
                        date: item.publish,
                    }));

                    this.setState({
                        raffles,
                        winners: resultWinners,
                        currentRaffleId: raffles[0]?.id,
                        isReady: true,
                    });
                },
                () => {},
            );
    }

    componentDidMount() {
        this.getWinners();
    }

    render() {
        const { search = '', currentRaffleId, raffles = [], isReady } = this.state;
        const { user } = this.props;
        const condForMore = this.getItems(false).length > this.state.currentStep;
        const currentRaffle = raffles.find((item) => item.id === currentRaffleId);
        const emptyTitle = `Победители будут <br class="_mobileMedia" />опубликованы ${currentRaffle?.publish}`;

        return (
            <>
                <div ref={this.parent} className="winnersHeader" id="winners">
                    <div className="winnersHeader__inner">
                        <div className="winnersHeader__head">
                            <div className="winnersHeader__headTitle">
                                Победители <br className="_mobileMedia" />
                                розыгрышей
                            </div>
                            <div className="winnersHeader__headDescription">
                                Подробную информацию <br className="_mobileMedia" />см. в{' '}
                                <Link
                                    href={user ? 'profile' : 'login'}
                                    className="winnersHeader__headDescriptionLink"
                                >
                                    личном кабинете
                                </Link>
                            </div>
                            <div className="winnersHeader__headFilter">
                                <div className="winnersHeader__headFilterBlock">
                                    <div className="winnersHeader__headFilterBlockContent">
                                        <Select
                                            value={currentRaffleId}
                                            list={raffles.map((item) => ({
                                                key: item.id,
                                                content: `${item.from.slice(
                                                    0,
                                                    5,
                                                )} — ${item.to.slice(0, 5)}`,
                                            }))}
                                            onChange={this.handlerSelect}
                                            support="—"
                                        />
                                    </div>
                                    <div className="winnersHeader__headFilterBlockSupport">
                                        Выберите неделю, чтобы посмотреть победителей
                                    </div>
                                </div>
                                <div className="winnersHeader__headFilterBlock">
                                    <div className="winnersHeader__headFilterBlockContent">
                                        <i className="winnersHeader__headFilterInputIcon">
                                            <Icon name="search" />
                                        </i>
                                        <input
                                            value={search}
                                            type="text"
                                            className="winnersHeader__headFilterInput"
                                            placeholder="Поиск по номеру телефона"
                                            onChange={this.handlerSearch}
                                        />
                                    </div>
                                    <div className="winnersHeader__headFilterBlockSupport">
                                        Введите последние 4 цифры
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="winnersHeader__table">
                            <Table
                                name="winners"
                                items={this.getItems()}
                                isReady={isReady}
                                showLoader={!isReady}
                                emptyTitle={
                                    search?.length
                                        ? `По вашему фильтру ничего не найдено`
                                        : emptyTitle
                                }
                                renderUpdateKey={search}
                            />
                        </div>
                        <AnimateChange className="winnersHeader__button" renderKey={condForMore}>
                            {condForMore && (
                                <Button
                                    className="_bigSize _white"
                                    onClick={() => {
                                        if (condForMore) {
                                            this.setState(
                                                {
                                                    currentStep:
                                                        this.state.currentStep + this.stepCounter,
                                                },
                                                () => {
                                                    removeTransition({
                                                        item: '.table__rowWrapper',
                                                        isCurrent: true,
                                                    });
                                                },
                                            );
                                        }
                                    }}
                                >
                                    Показать еще
                                </Button>
                            )}
                        </AnimateChange>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(WinnersHeader);

WinnersHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
