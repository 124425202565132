import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';

import handlerPopup from '../../functions/handlerPopup';
import Link from '../../components/Link.jsx';
import setSpacesInText from '../../functions/setSpacesInText';
import Slider from '../../functions/Slider';

class IndexPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    sliderInit() {
        const slider = this.parent.current.querySelector('.indexPrizes__content');

        this.slider = new Slider({
            slider,
            lay: slider.querySelector('.indexPrizes__cards'),
            items: slider.querySelectorAll('.indexPrizes__card'),
            infinity: false,
            withResize: true,
            current: 0,
            buttons: {
                prev: slider.querySelector('.indexPrizes__arrow._prev'),
                next: slider.querySelector('.indexPrizes__arrow._next'),
            },
            type: 'mobile',
        });
    }

    checkInit() {
        const { components } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;

            this.sliderInit();
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    render() {
        const { device, components, user } = this.props;
        const content = components?.raffle3;
        const mainContent = components?.raffle4;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexPrizes _special ${content ? '_ready' : ''}`}
                >
                    <div className="indexPrizes__inner">
                        <div className="indexPrizes__head">
                            <div className="indexPrizes__headTitle">
                                {content?.info?.header?.title}
                            </div>
                            <div className="indexPrizes__headSubTitle _support">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(content?.info?.header?.description),
                                    }}
                                ></span>
                                <i className="indexPrizes__headSubTitleInfoWrapper">
                                    <i
                                        className="indexPrizes__headSubTitleInfo"
                                        onClick={() => {
                                            if (device === 'mobile') {
                                                handlerPopup({
                                                    name: 'specialPrizeInfoPopup',
                                                    isShow: true,
                                                    content: content?.info?.tip?.description,
                                                });
                                            }
                                        }}
                                    >
                                        i
                                        <div
                                            className="indexPrizes__headSubTitleInfoWindow"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.info?.tip?.description,
                                                ),
                                            }}
                                        ></div>
                                    </i>
                                </i>
                            </div>
                        </div>
                        <div className="indexPrizes__decor _4"></div>
                        <div className="indexPrizes__decor _5"></div>
                        <div className="indexPrizes__content">
                            <div className="indexPrizes__arrow _prev">
                                <div className="arrowBtn _prev">
                                    <img
                                        src={require('../../media/icon-arrow-prev.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexPrizes__arrow _next">
                                <div className="arrowBtn _next">
                                    <img
                                        src={require('../../media/icon-arrow-next.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexPrizes__cards">
                                {content?.prizes?.map((prize, key) => (
                                    <div className="indexPrizes__card _medium" key={key}>
                                        <div className="indexPrizes__cardInner">
                                            <div className="indexPrizes__cardHead">
                                                <img
                                                    src={prize.thumb}
                                                    alt=""
                                                    className="indexPrizes__cardImage"
                                                />
                                            </div>
                                            <div className="indexPrizes__cardName">
                                                {prize.title}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Link className="indexPrizes__button" href={user ? 'cheques' : 'login'}>
                            <Button className="_white _2 _bigSize _wide _pulsation">
                                зарегистрировать чек
                            </Button>
                        </Link>
                        <div className="indexPrizes__main">
                            <div className="indexPrizes__mainHead">
                                <div className="indexPrizes__mainHeadTitle">
                                    {mainContent?.header?.title}
                                </div>
                                <div className="indexPrizes__mainHeadDescription _dark">
                                    {mainContent?.header?.description}
                                </div>
                            </div>
                            <div className="indexPrizes__mainContent">
                                <div className="indexPrizes__mainContentPreview">
                                    <img
                                        src={mainContent?.prize?.thumb}
                                        alt=""
                                        className="indexPrizes__mainContentPreviewImage"
                                    />
                                </div>
                                <div className="indexPrizes__mainContentInner">
                                    <p className="indexPrizes__mainContentSupport">
                                        {mainContent?.prize?.title}
                                    </p>
                                    <div className="indexPrizes__mainContentTitle">
                                        {mainContent?.prize?.description}
                                    </div>
                                    <div className="indexPrizes__mainContentInfo">
                                        {mainContent?.tip1?.title}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {mainContent?.url && (
                            <a
                                className="indexPrizes__button"
                                target="_blank"
                                href={mainContent?.url?.url}
                                rel="noreferrer"
                            >
                                <Button className="_white _3 _bigSize _wide _pulsation">
                                    {mainContent?.url?.title}
                                </Button>
                            </a>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexPrizes);

IndexPrizes.propTypes = {
    device: PropTypes.string,
    components: PropTypes.object,
    user: PropTypes.object,
};
