import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';

class WaitAbout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components } = this.props;
        const footer = components?.footer;

        return (
            <>
                <div ref={this.parent} className="waitAbout">
                    <div className="waitAbout__inner">
                        <a
                            className="waitAbout__button"
                            href={footer?.url3?.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button className="_white _mediumSize _wide">Правила акции</Button>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(WaitAbout);

WaitAbout.propTypes = {
    device: PropTypes.string,
    components: PropTypes.object,
};
