import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import WinnersHeader from './winners/Header.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';

class Winners extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    // isPrivate = true;

    pageName = 'winners';

    contentName = 'winners';

    render() {
        const { components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _winners _inner">
                    <div className="page__section">
                        <WinnersHeader components={components} />
                    </div>
                    <div className="page__section _footer">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Winners);

Winners.propTypes = {
    device: PropTypes.string,
};
