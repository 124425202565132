import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import pages from '../../redux/pages';
import getPageLink from '../../functions/getPageLink';
import Pages from '../../components/Pages.jsx';
import handlerPopup from '../../functions/handlerPopup';
import Prizes from './pages/Prizes.jsx';
import Cheques from './pages/Cheques.jsx';
import Codes from './pages/Codes.jsx';
import scrollToPosition from '../../functions/scrollToPosition';
import Sounds from './pages/Sounds.jsx';

class ProfileContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setUpdateKey = this.setUpdateKey.bind(this);
        this.scrollToSounds = this.scrollToSounds.bind(this);

        this.parent = React.createRef();
    }

    codes = [
        {
            id: 1,
            code: '2903124',
            date: '05.03.2024',
        },
        {
            id: 2,
            code: '2903124',
            date: '05.03.2024',
        },
        {
            id: 3,
            code: '2903124',
            date: '05.03.2024',
        },
        {
            id: 4,
            code: '2903124',
            date: '05.03.2024',
        },
    ];

    pages = {
        'profile-codes': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Codes
                            parent={this.parent}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                            components={components}
                        />
                    </>
                );
            },
        },
        'profile-sounds': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Sounds
                            parent={this.parent}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                            components={components}
                        />
                    </>
                );
            },
        },
        'profile-cheques': {
            render() {
                const { privateContent, components } = this.props;

                return (
                    <>
                        <Cheques
                            parent={this.parent}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                            components={components}
                        />
                    </>
                );
            },
        },
        'profile-prizes': {
            render() {
                const { privateContent } = this.props;

                return (
                    <>
                        <Prizes
                            parent={this.parent}
                            privateContent={privateContent}
                            setUpdateKey={this.setUpdateKey}
                        />
                    </>
                );
            },
        },
    };

    setUpdateKey() {
        this.setState({ pagesUpdateKey: new Date().getTime() });
    }

    scrollToSounds({ detail: { duration = 300 } }) {
        const topBar = document.querySelector('.topBar');
        let offset = 0;

        if (topBar) {
            offset = topBar.offsetHeight + 50;
        }

        scrollToPosition({
            position: 'top',
            parent: this.parent.current.closest('.body__page'),
            classNameElem: '.profileContent__pages',
            offset,
            duration,
        });
    }

    componentDidMount() {
        document.addEventListener('scrollToSounds', this.scrollToSounds);
    }

    componentWillUnmount() {
        document.removeEventListener('scrollToSounds', this.scrollToSounds);
    }

    render() {
        const { pagesUpdateKey } = this.state;
        const { storePages, components } = this.props;
        const profilePages = pages.filter(
            (page) => page.parentName === 'profile' && page.level === 1,
        );
        const buttons = components?.buttons;

        return (
            <>
                <div ref={this.parent} className="profileContent">
                    <div className="profileContent__inner">
                        <div className="profileContent__buttons">
                            <div className="profileContent__button">
                                <Button
                                    className="_white _mediumSize _wide"
                                    onClick={() => {
                                        handlerPopup({ name: 'codePopup', isShow: true });
                                    }}
                                >
                                    зарегистрировать код
                                </Button>
                            </div>
                            <Link href="cheques" className="profileContent__button">
                                <Button className="_white _mediumSize _wide">
                                    зарегистрировать чек
                                </Button>
                            </Link>
                            <Link
                                className="profileContent__button _sounds"
                                href="profile/sounds"
                                callback={() => {
                                    this.scrollToSounds({ detail: {} });
                                }}
                            >
                                <Button className="_green _mediumSize _wide">
                                    {buttons?.jingle?.title || 'Создать джингл'}
                                </Button>
                            </Link>
                        </div>
                        <div className="profileContent__content">
                            <div className="profileContent__tabs">
                                {profilePages.map((page, key) => {
                                    const { content } = page;

                                    return (
                                        <div className="profileContent__tab" key={key}>
                                            <Link
                                                className="profileContent__tabInner"
                                                pageName={page.name}
                                                href={getPageLink({ name: page.name, storePages })}
                                            >
                                                {content}
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                            <Pages
                                className="profileContent__pages"
                                classNamePage="profileContent__page"
                                filter={(page) => page.parentName === 'profile' && page.level === 1}
                                pages={this.pages}
                                context={this}
                                isFull={false}
                                isNotParams={false}
                                keyRender={pagesUpdateKey}
                                // name="test"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(ProfileContent);

ProfileContent.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    storePages: PropTypes.object,
    components: PropTypes.object,
};
