import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class WaitHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { device, components } = this.props;
        const counter = components?.counter;

        return (
            <>
                <div ref={this.parent} className={`waitHeader ${counter ? '_ready' : ''}`}>
                    <div className="waitHeader__inner">
                        <div className="waitHeader__content">
                            <img
                                src={require('../../media/slogan-music.svg').default}
                                alt=""
                                className="waitHeader__slogan"
                            />
                            <div className="waitHeader__title">
                                Покупай напитки «Добрый» и&nbsp;получай топовые музыкальные призы!
                            </div>
                            <div className="waitHeader__info">
                                <div className="waitHeader__infoTitle">{counter?.title}</div>
                                <div className="waitHeader__infoInner">
                                    <div className="waitHeader__infoCounter">
                                        <div className="waitHeader__infoCounterValue">
                                            {counter?.days}
                                        </div>
                                        <p className="waitHeader__infoCounterSupport">
                                            {counter?.daysTitle}
                                        </p>
                                    </div>
                                    <div className="waitHeader__infoDate">
                                        {device === 'mobile' ? (
                                            <>
                                                Дата старта:
                                                <br />1 мая
                                            </>
                                        ) : (
                                            'Дата старта - 1 мая'
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(WaitHeader);

WaitHeader.propTypes = {
    device: PropTypes.string,
    components: PropTypes.object,
};
