import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';

import changePage from '../functions/changePage';
import getNotPopupPage from '../functions/getNotPopupPage';
import AnimateChange from './AnimateChange.jsx';
import AuthPage from './AuthPage.jsx';
import handlerLoading from '../functions/handlerLoading';
import handlerPopup from '../functions/handlerPopup';

class Popup extends AuthPage {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.handlerField = this.handlerField.bind(this);
        this.handlerLoading = this.handlerLoading.bind(this);
        this.renderError = this.renderError.bind(this);

        this.parent = React.createRef();
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    fields[name] = value;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    renderError({ error }) {
        const { setUpdateKey } = this.props;

        return (
            <AnimateChange
                className="popup__error _center"
                renderKey={error}
                callback={() => {
                    this.setState({ renderUpdateKey: new Date().getTime() });

                    if (typeof setUpdateKey === 'function') {
                        setUpdateKey();
                    }
                }}
                paramsParent={{
                    width: document.querySelector('.popup__foot')?.offsetWidth,
                }}
                itemParams={['width']}
            >
                {error && <div className="error _mediumSize">{error}</div>}
            </AnimateChange>
        );
    }

    handlerLoading(loadingKey, otherProps) {
        return handlerLoading.call(this, loadingKey, otherProps);
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.setRef) {
            this.props.setRef(this.parent);
        }
    }

    componentDidUpdate() {
        super.componentDidUpdate();
    }

    render() {
        const { children, name, withoutClose, className = '' } = this.props;

        return (
            <>
                <div ref={this.parent} className={`popup ${name ? `_${name}` : ''} ${className}`}>
                    <div className="popup__inner">
                        {!withoutClose && (
                            <div
                                className="popup__close"
                                onClick={() => {
                                    if (name) {
                                        handlerPopup({ name, isShow: false });
                                    } else {
                                        changePage(getNotPopupPage());
                                    }
                                }}
                            >
                                <i className="popup__closeIcon">
                                    <Icon name="close" />
                                </i>
                            </div>
                        )}

                        <div className="popup__innerBox">{children}</div>
                    </div>
                </div>
            </>
        );
    }
}

export default Popup;

Popup.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
    withoutClose: PropTypes.bool,
    setRef: PropTypes.func,
    inPage: PropTypes.bool,
    className: PropTypes.string,
};
