import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Cheques from './Cheques.jsx';

class ChequeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <>
                <div className="body__chequeForm">
                    <div className="body__chequeFormPopup">
                        <Cheques inPage={true} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(ChequeForm);

ChequeForm.propTypes = {
    device: PropTypes.string,
};
