import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';
import handlerLoading from '../functions/handlerLoading';
import regCode from '../functions/regCode';
import changePage from '../functions/changePage';

class CodePopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.renderBlock = this.renderBlock.bind(this);
    }

    isPrivate = true;

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ id: currentBlock }];
    }

    setCurrentBlock(currentBlock) {
        this.setState({
            currentBlock,
            ...(currentBlock === 'start' ? { code: null, error: null } : {}),
        });
    }

    changeCode({ code }) {
        const resultCode = code
            .replace(/[^\da-z]/gi, '')
            .slice(0, 10)
            .toUpperCase();

        this.setState({ code: resultCode });
    }

    renderBlock({ prop: id }) {
        const { error, code, loadingKey } = this.state;

        return (
            <div className="popup__block">
                {id === 'start' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">Регистрация кода</div>
                        </div>
                        <div className="popup__content">
                            <div className="popup__code">
                                <p className="popup__codeSupport">Введи код под крышкой</p>
                                <input
                                    type="text"
                                    className="popup__codeInput"
                                    value={code}
                                    onChange={({ target }) =>
                                        this.changeCode({ code: target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="popup__foot">
                            {this.renderError({ error })}
                            <div className="popup__buttons">
                                <div className="popup__button _mediumSize">
                                    <Button
                                        className="_red _2 _mediumSize _wide"
                                        onClick={() => {
                                            this.sendCode();
                                        }}
                                        loader={loadingKey === 'code'}
                                    >
                                        Отправить
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {id === 'success' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">Регистрация кода</div>
                        </div>
                        <div className="popup__content">
                            <div className="popup__codeSuccess">
                                <div className="popup__codeSuccessText">Код принят!</div>
                                <div className="popup__codeSuccessText">
                                    Тебе начислены 1 звук и одна фраза{' '}
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();

                                            handlerPopup({ name: 'codePopup', isShow: false });

                                            changePage({ href: 'profile/sounds' }).then(() => {
                                                document.dispatchEvent(
                                                    new CustomEvent('scrollToSounds', {
                                                        detail: {},
                                                    }),
                                                );
                                            });
                                        }}
                                    >
                                        в&nbsp;Переводчике
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            <div className="popup__buttons">
                                <div className="popup__button">
                                    <Button
                                        className="_emptyRed _2 _mediumSize _wide"
                                        onClick={() => {
                                            this.setCurrentBlock('start');
                                        }}
                                    >
                                        Ещё код
                                    </Button>
                                </div>
                                <div className="popup__button">
                                    <Button
                                        className="_red _2 _mediumSize _wide"
                                        onClick={() => {
                                            handlerPopup({ name: 'codePopup', isShow: false });
                                        }}
                                    >
                                        Закрыть окно
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    sendCode() {
        const { code } = this.state;

        handlerLoading.call(this, 'code').then(() => {
            regCode(code, undefined, 'site').then(
                (res) => {
                    handlerLoading.call(this, null);

                    if (res.data?.result === 'OK') {
                        this.setCurrentBlock('success');

                        document.dispatchEvent(new CustomEvent('updateProfile'));
                    }
                },
                (err) => {
                    this.setState({ error: err?.response?.data?.errorText });

                    handlerLoading.call(this, null);
                },
            );
        });
    }

    render() {
        const { currentBlock, renderUpdateKey } = this.state;

        return (
            <>
                <Popup
                    name="codePopup"
                    setRef={(parent) => {
                        this.parent = parent;
                    }}
                >
                    <ListAbsoluteMain
                        className="popup__blocks"
                        items={this.getBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="popup__block"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        currentItemKey={currentBlock}
                        allItems={['start', 'success']}
                        resizeParent={document.querySelector('.body')}
                        keyRender={renderUpdateKey}
                    />
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(CodePopup);

CodePopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
