import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import FullForm from './anket/FullForm.jsx';

class FullAnket extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'fullAnket';

    contentName = 'main';

    render() {
        const { components } = this.state;
        const { user, isShort } = this.props;

        return (
            <>
                <div ref={this.parent} className={`page _anket _inner ${isShort ? '_short' : ''}`}>
                    <div className="page__section">
                        <div className="innerPageHead _outside">
                            <div className="innerPageHead__title">Анкета</div>
                            <p className="innerPageHead__description _mediumSize">
                                Для получения приза, пожалуйста, заполните все поля
                                <br className="_desktopMedia" /> и&nbsp;приложите необходимые
                                документы
                            </p>
                        </div>
                    </div>
                    <div className="page__section">
                        <FullForm components={components} anketFields={user?.extraDataRequired} />
                    </div>
                    {!isShort && (
                        <div className="page__section _footer">
                            <Footer footer={components?.footer} />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(FullAnket);

FullAnket.propTypes = {
    device: PropTypes.string,
    isShort: PropTypes.bool,
};
