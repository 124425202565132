import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../scss/main.scss';

import { dispatcher } from '../redux/redux';

import getCurrentPage from '../functions/getCurrentPage';
import changePage from '../functions/changePage';

import Pages from '../components/Pages.jsx';
import TopBar from '../components/TopBar.jsx';
import MobileMenu from '../components/MobileMenu.jsx';

import Index from './Index.jsx';
import About from './About.jsx';
import Faq from './Faq.jsx';
import Anket from './Anket.jsx';
import PrizeAnket from './PrizeAnket.jsx';
import Act from './Act.jsx';
import Profile from './Profile.jsx';
import Wait from './Wait.jsx';
import Winners from './Winners.jsx';

import saveJWT from '../functions/saveJWT';
import { getCookie, setCookie } from '../functions/handlerCookies';
import checkAuth from '../functions/checkAuth';

import Widget from './Widget.jsx';
import ChequeForm from './ChequeForm.jsx';
import Share from './Share.jsx';
import FullAnket from './FullAnket.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerAncor = this.handlerAncor.bind(this);
        this.setScroll = this.setScroll.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    // waitMode = true;

    pages = {
        index: {
            render() {
                return (
                    <>
                        <Index setScroll={this.setScroll} />
                    </>
                );
            },
        },
        about: {
            render() {
                return (
                    <>
                        <About setScroll={this.setScroll} />
                    </>
                );
            },
        },
        faq: {
            render() {
                return (
                    <>
                        <Faq setScroll={this.setScroll} />
                    </>
                );
            },
        },
        anket: {
            render() {
                return (
                    <>
                        <Anket setScroll={this.setScroll} />
                    </>
                );
            },
        },
        prizeAnket: {
            render() {
                return (
                    <>
                        <PrizeAnket setScroll={this.setScroll} />
                    </>
                );
            },
        },
        act: {
            render() {
                return (
                    <>
                        <Act setScroll={this.setScroll} />
                    </>
                );
            },
        },
        winners: {
            render() {
                return (
                    <>
                        <Winners setScroll={this.setScroll} />
                    </>
                );
            },
        },
        profile: {
            render() {
                return (
                    <>
                        <Profile setScroll={this.setScroll} />
                    </>
                );
            },
        },
        widget: {
            render() {
                return (
                    <>
                        <Widget />
                    </>
                );
            },
        },
        chequeForm: {
            render() {
                return (
                    <>
                        <ChequeForm />
                    </>
                );
            },
        },
        share: {
            render() {
                return (
                    <>
                        <Share />
                    </>
                );
            },
        },
        fullAnket: {
            render() {
                return (
                    <>
                        <FullAnket setScroll={this.setScroll} />
                    </>
                );
            },
        },
        anketForm: {
            render() {
                return (
                    <>
                        <FullAnket isShort={true} />
                    </>
                );
            },
        },
        404: {
            render() {
                return <>404</>;
            },
        },
    };

    visibilityDocChange() {
        if (!document.hidden) {
            checkAuth();
        }

        document.dispatchEvent(
            new CustomEvent('changeActivity', { detail: { active: !document.hidden } }),
        );
    }

    handlerAncor({ detail: { name, changeIsHard } }) {
        const { storePages } = this.props;
        const currentPage = getCurrentPage({ pages: storePages, filter: (page) => !page.level });

        if (currentPage !== 'index') {
            dispatcher({ type: 'indexAncor', data: name });
            changePage({ href: '', changeIsHard });
            dispatcher({ type: 'mobileMenuShow', data: false });
        } else {
            document.dispatchEvent(new CustomEvent('indexScroll', { detail: { name } }));
        }
    }

    setScroll(scrollTop) {
        const { device, storePages } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => !page.isPopup && !page.level,
        });
        const stopValue = device === 'mobile' || currentPage !== 'index' ? 1 : 300;

        if (scrollTop >= stopValue && !this.state.topBarActive) {
            this.setState({ topBarActive: true });
        }

        if (scrollTop < stopValue && this.state.topBarActive) {
            this.setState({ topBarActive: false });
        }
    }

    componentDidMount() {
        document.addEventListener('indexAncor', this.handlerAncor);
        document.addEventListener('visibilitychange', this.visibilityDocChange);

        const rootJWT = document.querySelector('#root').getAttribute('data-jwt');

        if (rootJWT) {
            setCookie(process.env.REACT_APP_HASH, rootJWT);
        }

        saveJWT(getCookie(process.env.REACT_APP_HASH));

        checkAuth();

        window.getJWT = () => getCookie(process.env.REACT_APP_HASH);
        window.saveJWT = saveJWT;
        window.apiUrl = process.env.REACT_APP_API;
    }

    render() {
        const { topBarActive } = this.state;
        const { windowIsReady, device, storePages } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => !page.isPopup && !page.level,
        });

        return (
            <>
                <div
                    ref={this.parent}
                    className={`body__contentInner ${this.waitMode ? '_waitMode' : ''}`}
                >
                    {this.waitMode ? (
                        <>
                            <div className="body__page _show">
                                <Wait />
                            </div>
                        </>
                    ) : (
                        <>
                            {!['widget', 'chequeForm', 'anketForm'].includes(currentPage) && (
                                <>
                                    {device === 'mobile' && (
                                        <>
                                            <MobileMenu />
                                        </>
                                    )}

                                    <TopBar
                                        isActive={topBarActive}
                                        isInner={currentPage !== 'index'}
                                    />
                                </>
                            )}

                            {windowIsReady && (
                                <Pages
                                    className="body__pages"
                                    classNamePage="body__page"
                                    filter={(page) => Object.keys(this.pages).includes(page.name)}
                                    pages={this.pages}
                                    context={this}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        storePages: state.pages,
        loadingImage: state.loadingImage,
        windowIsReady: state.windowIsReady,
        user: state.user,
        orderPopup: state.orderPopup,
        codePopup: state.codePopup,
        emailPopup: state.emailPopup,
        gamePopup: state.gamePopup,
        specialPrizeInfoPopup: state.specialPrizeInfoPopup,
        soundsPopup: state.soundsPopup,
        referalPopup: state.referalPopup,
        loades: state.loades,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    device: PropTypes.string,
    windowIsReady: PropTypes.bool,
    storePages: PropTypes.object,
    loadingImage: PropTypes.string,
    user: PropTypes.object,
    orderPopup: PropTypes.object,
    emailPopup: PropTypes.object,
    codePopup: PropTypes.object,
    gamePopup: PropTypes.object,
    specialPrizeInfoPopup: PropTypes.object,
    soundsPopup: PropTypes.object,
    referalPopup: PropTypes.object,
    loades: PropTypes.array,
};
