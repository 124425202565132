import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import regCode from '../functions/regCode';

import getHeaders from '../functions/getHeaders';
import requestSuccess from '../functions/requestSuccess';

class Act extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.initWidget = this.initWidget.bind(this);

        this.parent = React.createRef();
    }

    initWidget() {
        const { privateContent } = this.state;

        if (privateContent && window.DCAudioWiget) {
            const settings = {
                rootRef: '#audioWidget',
                JWT: privateContent?.widgetJWT,
                regCode: (code, callback) => {
                    regCode(code, callback, 'bot-widget');
                },
                channel: 'bot',
            };

            window.DCAudioWiget.init(settings);
        }
    }

    getPrivateContent() {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetCabinetInfo`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    this.setState({ privateContent: res.data.data }, this.initWidget);
                },
                () => {},
            );
    }

    componentDidMount() {
        this.getPrivateContent();
    }

    render() {
        return (
            <>
                <div className="body__widget">
                    <div className="body__widgetInner" id="audioWidget"></div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Act);

Act.propTypes = {
    device: PropTypes.string,
};
