import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';

import pages from './redux/pages';
import Root from './views/Root.jsx';

import { dispatcher, store } from './redux/redux';

import resize from './functions/handlerSize';
import changePage from './functions/changePage';
import requestSuccess from './functions/requestSuccess';

if (0) {
    axios.interceptors.response.use(
        (response) => {
            requestSuccess(response);

            return response;
        },
        (error) => new Error(error),
    );
}

const resultPages = {};

const path = window.location.pathname.slice(1);

pages.forEach((page) => {
    resultPages[page.name] = {
        isShow: false,
    };
});

const { storePages, levels, pagesIds } = changePage({ href: path, storePages: resultPages });

dispatcher({ type: 'pages', data: storePages });
dispatcher({ type: 'levels', data: levels });
dispatcher({ type: 'pagesIds', data: pagesIds });

document.addEventListener('scroll', () => {
    resize();
});

window.addEventListener('resize', () => {
    resize();
});

document.oncontextmenu = (e) => {
    e.preventDefault();
};

resize(true);

const loads = {};
const checkLoad = () => {
    if (loads.event && loads.fonts) {
        setTimeout(() => {
            dispatcher({ type: 'windowIsLoad', data: true });
            dispatcher({ type: 'windowIsReady', data: true });

            resize(true);

            document.dispatchEvent(new CustomEvent('windowReady'));
        }, 10);
    }
};

document.fonts.ready.then(() => {
    setTimeout(() => {
        loads.fonts = true;

        dispatcher({ type: 'isLoadFonts', data: true });

        checkLoad();
    }, 10);
});

window.onload = () => {
    let intervalId;
    let counter = 0;

    setTimeout(() => {
        dispatcher({ type: 'windowIsLoad', data: new Date().getTime() });

        intervalId = setInterval(() => {
            counter += 1;
            const loades = [...(store.getState().loades || [])];

            loades.push(true);

            dispatcher({ type: 'loades', data: loades });

            if (counter >= 16) {
                clearInterval(intervalId);
            }
        }, 50);

        loads.event = true;

        checkLoad();
    }, 10);
};

// const observer = new MutationObserver(() => {
//     disableElems();
// });

// observer.observe(document.querySelector('body'), {
//     childList: true,
//     subtree: true,
//     characterDataOldValue: true,
// });

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    rootElement,
);
