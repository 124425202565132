import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import setSpacesInText from '../functions/setSpacesInText';

class SpecialPrizeInfoPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { specialPrizeInfoPopup } = this.props;

        return (
            <>
                <Popup name="specialPrizeInfoPopup">
                    <div
                        className="popup__prizeInfo"
                        dangerouslySetInnerHTML={{
                            __html: setSpacesInText(specialPrizeInfoPopup?.content),
                        }}
                    ></div>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        specialPrizeInfoPopup: state.specialPrizeInfoPopup,
    };
}

export default connect(mapStateToProps)(SpecialPrizeInfoPopup);

SpecialPrizeInfoPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    specialPrizeInfoPopup: PropTypes.object,
};
