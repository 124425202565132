import axios from 'axios';

import getHeaders from './getHeaders';
import requestSuccess from './requestSuccess';
import checkAuth from './checkAuth';

export default function regCode(code, callback, interfaceVal) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_API}/api/CodeRegistration`,
                { code, ...(interfaceVal ? { interface: interfaceVal } : {}) },
                { headers: getHeaders() },
            )
            .then(
                (res) => {
                    requestSuccess(res);

                    if (res.data?.result === 'OK') {
                        checkAuth();

                        if (window.ym) {
                            window.ym(
                                96951425,
                                'reachGoal',
                                code.length === 10 ? 'regCodeB' : 'regCodeCC',
                            );
                        }
                    }

                    if (callback) {
                        callback(true, res);
                    }

                    resolve(res);
                },
                (err) => {
                    if (callback) {
                        callback(false, err);
                    }

                    if (window.ym) {
                        window.ym(96951425, 'reachGoal', 'regCodeError');
                    }

                    reject(err);
                },
            );
    });
}
