import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../../functions/Slider';

import Button from '../../components/Button.jsx';
import handlerPopup from '../../functions/handlerPopup';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';
import scrollToPosition from '../../functions/scrollToPosition';

class ProfileCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.scrollToCodes = this.scrollToCodes.bind(this);

        this.parent = React.createRef();
    }

    sliders = {};

    sliderInit() {
        const { info } = this.props;

        this.parent.current.querySelectorAll('.profileCodes__card').forEach((card, key) => {
            this.sliders[key] = new Slider({
                id: key === 0 ? 'ruffle' : 'merch',
                slider: card,
                lay: card.querySelector('.profileCodes__cardItems'),
                items: card.querySelectorAll('.profileCodes__cardItem'),
                infinity: true,
                withResize: true,
                current: 0,
                buttons: {
                    prev: card.querySelector('.profileCodes__cardContentArrow._prev'),
                    next: card.querySelector('.profileCodes__cardContentArrow._next'),
                },
                callback: ({ id, type, current }) => {
                    if (id === 'merch' && type === 'change') {
                        this.setState({ currentMerch: info?.merch?.prizes[current].code });
                    }
                },
            });
        });
    }

    checkInit() {
        const { info } = this.props;

        if (info && !this.isInit) {
            this.isInit = true;

            this.setState({ isInit: true }, () => {
                this.sliderInit();
            });
        }
    }

    scrollToCodes({ detail: { duration = 0 } }) {
        const topBar = document.querySelector('.topBar');
        let offset = 0;

        if (topBar) {
            offset = topBar.offsetHeight + 50;
        }

        scrollToPosition({
            position: 'center',
            parent: this.parent.current.closest('.body__page'),
            classNameElem: '.profileCodes__cardButton._moment',
            offset,
            duration,
        });
    }

    componentDidMount() {
        this.checkInit();

        document.addEventListener('scrollToCodes', this.scrollToCodes);
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        document.removeEventListener('scrollToCodes', this.scrollToCodes);

        Object.keys(this.sliders).forEach((key) => {
            this.sliders[key].destroy();
        });
    }

    render() {
        const { isInit, currentMerch } = this.state;
        const { info } = this.props;

        return (
            <>
                <div ref={this.parent} className={`profileCodes ${isInit ? '_init' : ''}`}>
                    <div className="profileCodes__inner">
                        <div className="profileCodes__title _dark">Потрать коды</div>
                        <div className="profileCodes__content">
                            <Animate className="profileCodes__loader" isShow={!isInit}>
                                <div className="profileCodes__loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="profileCodes__card">
                                <div className="profileCodes__cardHead">
                                    <div className="profileCodes__cardTitle">
                                        {info?.raffle?.info?.header?.title}
                                    </div>
                                    <div className="profileCodes__cardDescription _purple">
                                        {info?.raffle?.info?.header?.description}
                                    </div>
                                </div>
                                <div className="profileCodes__cardContent">
                                    <div className="profileCodes__cardContentArrow _prev">
                                        <div className="arrowBtn _green _prev">
                                            <img
                                                src={
                                                    require('../../media/icon-arrow-prev.svg')
                                                        .default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="profileCodes__cardContentArrow _next">
                                        <div className="arrowBtn _green _next">
                                            <img
                                                src={
                                                    require('../../media/icon-arrow-next.svg')
                                                        .default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="profileCodes__cardContentInner">
                                        <div className="profileCodes__cardItems">
                                            {info?.raffle?.prizes?.map((prize) => (
                                                <div
                                                    className="profileCodes__cardItem"
                                                    key={prize.code}
                                                >
                                                    <img
                                                        src={prize.thumb}
                                                        alt=""
                                                        className="profileCodes__cardItemImage"
                                                    />
                                                    <p className="profileCodes__cardItemName">
                                                        {prize.title}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="profileCodes__cardButton _moment">
                                    <Button
                                        className="_red _2 _bigSize _pulsation"
                                        onClick={() => {
                                            handlerPopup({ name: 'gamePopup', isShow: true });
                                        }}
                                    >
                                        {info?.raffle?.info?.button?.title}
                                    </Button>
                                </div>
                            </div>
                            <div className="profileCodes__card">
                                <div className="profileCodes__cardHead">
                                    <div className="profileCodes__cardTitle">
                                        {info?.merch?.info?.header?.title}
                                    </div>
                                    <div className="profileCodes__cardDescription _blue">
                                        {info?.merch?.info?.header?.description}
                                    </div>
                                </div>
                                <div className="profileCodes__cardContent">
                                    <div className="profileCodes__cardContentArrow _prev">
                                        <div className="arrowBtn _green _prev">
                                            <img
                                                src={
                                                    require('../../media/icon-arrow-prev.svg')
                                                        .default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="profileCodes__cardContentArrow _next">
                                        <div className="arrowBtn _green _next">
                                            <img
                                                src={
                                                    require('../../media/icon-arrow-next.svg')
                                                        .default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="profileCodes__cardContentInner">
                                        <div className="profileCodes__cardItems">
                                            {info?.merch?.prizes?.map((prize) => (
                                                <div
                                                    className="profileCodes__cardItem"
                                                    key={prize.code}
                                                >
                                                    <img
                                                        src={prize.thumb}
                                                        alt=""
                                                        className="profileCodes__cardItemImage"
                                                    />
                                                    <p className="profileCodes__cardItemName">
                                                        {prize.title}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="profileCodes__cardButton">
                                    <Button
                                        className="_red _2 _bigSize _pulsation"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'orderPopup',
                                                isShow: true,
                                                currentMerch,
                                                items: info?.merch?.prizes,
                                                success: info?.merch?.info?.success,
                                                header: info?.merch?.info?.popupHeader,
                                            });
                                        }}
                                    >
                                        {info?.merch?.info?.button?.title}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileCodes);

ProfileCodes.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    info: PropTypes.object,
};
