import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    icons = {
        select: (
            <svg viewBox="0 0 17 11" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L8.5 6.37868L13.9393 0.93934C14.5251 0.353553 15.4749 0.353553 16.0607 0.93934C16.6464 1.52513 16.6464 2.47487 16.0607 3.06066L8.5 10.6213L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z"
                    fill="#6A1485"
                    className="icon__fill"
                />
            </svg>
        ),
        search: (
            <svg viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_427_10245)">
                    <path
                        d="M19.6343 17.871L15.7634 13.9984C18.6598 10.1278 17.87 4.64195 13.9994 1.74551C10.1288 -1.15092 4.64293 -0.361157 1.74649 3.50949C-1.14994 7.38013 -0.36018 12.866 3.51047 15.7624C6.61969 18.0891 10.8901 18.0891 13.9994 15.7624L17.872 19.635C18.3587 20.1217 19.1477 20.1217 19.6343 19.635C20.121 19.1483 20.121 18.3593 19.6343 17.8727L19.6343 17.871ZM8.78721 15.015C5.34716 15.015 2.55848 12.2263 2.55848 8.78623C2.55848 5.34618 5.34716 2.55751 8.78721 2.55751C12.2273 2.55751 15.0159 5.34618 15.0159 8.78623C15.0123 12.2247 12.2257 15.0113 8.78721 15.015Z"
                        fill="#2D2A26"
                        className="icon__fill"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_427_10245">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        check: (
            <svg viewBox="0 0 14 10" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1813 0.675393C13.5615 1.07599 13.5449 1.70894 13.1443 2.08912L5.3821 9.4557L0.858499 5.16268C0.457898 4.7825 0.441344 4.14955 0.821525 3.74895C1.20171 3.34835 1.83465 3.33179 2.23526 3.71197L5.3821 6.69842L11.7676 0.63842C12.1682 0.258239 12.8011 0.274793 13.1813 0.675393Z"
                    className="icon__fill"
                    fill="#fff"
                />
            </svg>
        ),
        upload: (
            <svg viewBox="0 0 24 24" fill="none">
                <path
                    d="M11.2044 18.0455C11.3089 18.1499 11.4329 18.2328 11.5694 18.2894C11.7059 18.3459 11.8522 18.375 12 18.375C12.1477 18.375 12.294 18.3459 12.4305 18.2894C12.567 18.2328 12.691 18.1499 12.7955 18.0455L17.0381 13.8029C17.2491 13.5919 17.3676 13.3057 17.3676 13.0074C17.3676 12.709 17.2491 12.4229 17.0381 12.2119C16.8271 12.0009 16.541 11.8824 16.2426 11.8824C15.9442 11.8824 15.6581 12.0009 15.4471 12.2119L13.125 14.534V2.25C13.125 1.95163 13.0064 1.66548 12.7954 1.4545C12.5845 1.24353 12.2983 1.125 12 1.125C11.7016 1.125 11.4154 1.24353 11.2045 1.4545C10.9935 1.66548 10.875 1.95163 10.875 2.25V14.534L8.55281 12.2119C8.34183 12.0009 8.05569 11.8824 7.75732 11.8824C7.45895 11.8824 7.1728 12.0009 6.96183 12.2119C6.75085 12.4229 6.63232 12.709 6.63232 13.0074C6.63232 13.3057 6.75085 13.5919 6.96183 13.8029L11.2044 18.0455Z"
                    fill="#721185"
                    className="icon__fill"
                />
                <path
                    d="M21.75 10.875C21.4516 10.875 21.1655 10.9935 20.9545 11.2045C20.7435 11.4155 20.625 11.7016 20.625 12V20.625H3.375V12C3.375 11.7016 3.25647 11.4155 3.0455 11.2045C2.83452 10.9935 2.54837 10.875 2.25 10.875C1.95163 10.875 1.66548 10.9935 1.4545 11.2045C1.24353 11.4155 1.125 11.7016 1.125 12V21C1.125 21.4973 1.32254 21.9742 1.67417 22.3258C2.02581 22.6775 2.50272 22.875 3 22.875H21C21.4973 22.875 21.9742 22.6775 22.3258 22.3258C22.6775 21.9742 22.875 21.4973 22.875 21V12C22.875 11.7016 22.7565 11.4155 22.5455 11.2045C22.3345 10.9935 22.0484 10.875 21.75 10.875Z"
                    fill="#721185"
                    className="icon__fill"
                />
            </svg>
        ),
        close: (
            <svg viewBox="0 0 19 19" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.21937 1.21961C1.80516 0.633827 2.7549 0.633827 3.34069 1.21961L9.42003 7.29895L15.4994 1.21961C16.0852 0.633827 17.0349 0.633827 17.6207 1.21961C18.2065 1.8054 18.2065 2.75515 17.6207 3.34093L11.5413 9.42027L17.6207 15.4996C18.2065 16.0854 18.2065 17.0351 17.6207 17.6209C17.0349 18.2067 16.0852 18.2067 15.4994 17.6209L9.42003 11.5416L3.34069 17.6209C2.7549 18.2067 1.80516 18.2067 1.21937 17.6209C0.633583 17.0351 0.633583 16.0854 1.21937 15.4996L7.29871 9.42027L1.21937 3.34093C0.633583 2.75515 0.633583 1.8054 1.21937 1.21961Z"
                    fill="#721185"
                    className="icon__fill"
                />
            </svg>
        ),
        scan: (
            <svg viewBox="0 0 39 39" fill="none">
                <path
                    d="M34.7846 0H29.3262C28.6952 0 28.1836 0.51157 28.1836 1.14258C28.1836 1.77359 28.6952 2.28516 29.3262 2.28516H34.7846C35.8489 2.28516 36.7148 3.15108 36.7148 4.21543V9.67383C36.7148 10.3048 37.2264 10.8164 37.8574 10.8164C38.4884 10.8164 39 10.3048 39 9.67383V4.21543C39 1.89104 37.109 0 34.7846 0V0Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M1.14258 10.8164C1.77359 10.8164 2.28516 10.3048 2.28516 9.67383V4.21543C2.28516 3.15108 3.15108 2.28516 4.21535 2.28516H9.67383C10.3048 2.28516 10.8164 1.77359 10.8164 1.14258C10.8164 0.51157 10.3048 0 9.67383 0H4.21535C1.89104 0 0 1.89104 0 4.21543V9.67383C0 10.3048 0.51157 10.8164 1.14258 10.8164Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M9.67383 36.7148H4.21535C3.15108 36.7148 2.28516 35.8489 2.28516 34.7846V29.3262C2.28516 28.6952 1.77359 28.1836 1.14258 28.1836C0.51157 28.1836 0 28.6952 0 29.3262V34.7846C0 37.109 1.89104 39 4.21535 39H9.67383C10.3048 39 10.8164 38.4884 10.8164 37.8574C10.8164 37.2264 10.3048 36.7148 9.67383 36.7148Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M37.8574 28.1836C37.2264 28.1836 36.7148 28.6952 36.7148 29.3262V34.7846C36.7148 35.8489 35.8489 36.7148 34.7846 36.7148H29.3262C28.6952 36.7148 28.1836 37.2264 28.1836 37.8574C28.1836 38.4884 28.6952 39 29.3262 39H34.7846C37.109 39 39 37.109 39 34.7846V29.3262C39 28.6952 38.4884 28.1836 37.8574 28.1836Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M26.0217 8.37891H29.6456C30.1405 8.37891 30.5431 8.78147 30.5431 9.27636V12.9002C30.5431 13.5312 31.0546 14.0427 31.6856 14.0427C32.3166 14.0427 32.8282 13.5312 32.8282 12.9002V9.27636C32.8282 7.52144 31.4005 6.09375 29.6456 6.09375H26.0217C25.3907 6.09375 24.8792 6.60532 24.8792 7.23633C24.8792 7.86734 25.3907 8.37891 26.0217 8.37891Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M6.16992 9.27636V12.9002C6.16992 13.5312 6.68149 14.0427 7.3125 14.0427C7.94351 14.0427 8.45508 13.5312 8.45508 12.9002V9.27636C8.45508 8.78147 8.85772 8.37891 9.35254 8.37891H12.9764C13.6074 8.37891 14.119 7.86734 14.119 7.23633C14.119 6.60532 13.6074 6.09375 12.9764 6.09375H9.35254C7.59769 6.09375 6.16992 7.52144 6.16992 9.27636Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M12.9764 30.4685H9.35254C8.85765 30.4685 8.45508 30.066 8.45508 29.5711V25.9473C8.45508 25.3163 7.94351 24.8047 7.3125 24.8047C6.68149 24.8047 6.16992 25.3163 6.16992 25.9473V29.5711C6.16992 31.326 7.59769 32.7537 9.35254 32.7537H12.9764C13.6074 32.7537 14.119 32.2421 14.119 31.6111C14.119 30.9801 13.6074 30.4685 12.9764 30.4685Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M32.8282 29.5711V25.9473C32.8282 25.3163 32.3166 24.8047 31.6856 24.8047C31.0546 24.8047 30.5431 25.3163 30.5431 25.9473V29.5711C30.5431 30.066 30.1404 30.4685 29.6456 30.4685H26.0217C25.3907 30.4685 24.8792 30.9801 24.8792 31.6111C24.8792 32.2421 25.3907 32.7537 26.0217 32.7537H29.6456C31.4005 32.7537 32.8282 31.326 32.8282 29.5711Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
                <path
                    d="M0.0761719 19.5C0.0761719 20.131 0.587742 20.6426 1.21875 20.6426H37.7812C38.4123 20.6426 38.9238 20.131 38.9238 19.5C38.9238 18.869 38.4123 18.3574 37.7812 18.3574H1.21875C0.587742 18.3574 0.0761719 18.869 0.0761719 19.5Z"
                    fill="#5F2589"
                    className="icon__fill"
                />
            </svg>
        ),
    };

    render() {
        return (
            <div className="icon">
                {this.icons[this.props.name] ? this.icons[this.props.name] : null}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Icon);

Icon.propTypes = {
    info: PropTypes.object,
    name: PropTypes.string,
    data: PropTypes.object,
};
