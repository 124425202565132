import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import regCode from '../../../functions/regCode';
import { dispatcher } from '../../../redux/redux';

class ProfileContentSounds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    init() {
        const { privateContent } = this.props;

        if (privateContent && window.DCAudioWiget && !this.isInit) {
            this.isInit = true;

            const settings = {
                rootRef: '#audioWidget',
                JWT: privateContent?.widgetJWT,
                regCode: (code, callback) => {
                    regCode(code, callback, 'site-widget');
                },
                channel: 'site',
            };

            window.DCAudioWiget.init(settings);
        }
    }

    componentDidMount() {
        this.init();

        const parent = document.querySelector('.profileContent__pageWidget');

        this.observer = new MutationObserver(() => {
            parent.querySelectorAll('input,textarea').forEach((input) => {
                input.onfocus = () => {
                    dispatcher({ type: 'isInputFocus', data: true });
                };

                input.onblur = () => {
                    dispatcher({ type: 'isInputFocus', data: false });
                };
            });
        });

        this.observer.observe(parent, {
            childList: true,
            subtree: true,
            characterDataOldValue: true,
        });
    }

    componentDidUpdate() {
        this.init();
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    render() {
        return (
            <>
                <div className="profileContent__pageHead">
                    <div className="profileContent__pageTitle">Мои Джинглы</div>
                </div>
                <div className="profileContent__pageContent">
                    <div className="profileContent__pageWidget" id="audioWidget"></div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentSounds);

ProfileContentSounds.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
};
