import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AсtForm from './act/Form.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import Button from '../components/Button.jsx';

class Act extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'aсt';

    contentName = 'main';

    render() {
        const { components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _act _inner">
                    <div className="page__section">
                        <div className="innerPageHead _outside">
                            <div className="innerPageHead__title">Запрос Акта</div>
                            <p className="innerPageHead__description _mediumSize">
                                Мы проверили Ваши данные, теперь Вам необходимо скачать
                                <br className="_desktopMedia" /> акт, подписать его, отсканировать
                                и&nbsp;загрузить в форме ниже
                            </p>
                            <div className="innerPageHead__button">
                                <Button className="_bigSize _white _2">Скачать акт</Button>
                            </div>
                        </div>
                    </div>
                    <div className="page__section">
                        <AсtForm components={components} />
                    </div>
                    <div className="page__section _footer">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(Act);

Act.propTypes = {
    device: PropTypes.string,
};
