import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import Link from './Link.jsx';
import Animate from './Animate.jsx';
import getNav from '../functions/getNav';
import { dispatcher } from '../redux/redux';
import changePage from '../functions/changePage';
import handlerPopup from '../functions/handlerPopup';

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { mobileMenuShow, mainContent, user } = this.props;
        const buttons = mainContent?.header?.buttons;

        const hideMenu = () => {
            setTimeout(() => {
                dispatcher({
                    type: 'mobileMenuShow',
                    data: false,
                });
            }, 100);
        };

        return (
            <Animate className="body__mobileMenu" isShow={mobileMenuShow}>
                <div className="mobileMenu">
                    <div className="mobileMenu__inner">
                        <div className="mobileMenu__content">
                            <div className="mobileMenu__nav">
                                {getNav().map((item) => {
                                    const { name, content, isAncor } = item;
                                    const LinkTag = isAncor ? 'div' : Link;
                                    const linkProps = isAncor
                                        ? {
                                              onClick: () => {
                                                  document.dispatchEvent(
                                                      new CustomEvent('indexAncor', {
                                                          detail: { name, changeIsHard: true },
                                                      }),
                                                  );

                                                  hideMenu();
                                              },
                                          }
                                        : {
                                              pageName: name,
                                              changeIsHard: true,
                                              prevActions: () => {
                                                  hideMenu();
                                              },
                                          };

                                    return (
                                        <div className="mobileMenu__navItem" key={name}>
                                            <LinkTag className="mobileMenu__navLink" {...linkProps}>
                                                {content}
                                            </LinkTag>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="mobileMenu__buttons">
                                <div
                                    href="cheques"
                                    className="mobileMenu__button"
                                    onClick={() => {
                                        if (user) {
                                            handlerPopup({ name: 'codePopup', isShow: true });
                                        } else {
                                            changePage({ href: 'login' });
                                        }

                                        hideMenu();
                                    }}
                                >
                                    <Button className="_white">{buttons?.button2?.title}</Button>
                                </div>
                                <Link
                                    href="login"
                                    className="mobileMenu__button"
                                    callback={() => {
                                        hideMenu();
                                    }}
                                >
                                    <Button className="_emptyWhite">
                                        {buttons?.button3?.title}
                                    </Button>
                                </Link>
                                <a
                                    className="mobileMenu__button"
                                    href={buttons?.button1?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button className="_blue">{buttons?.button1?.title}</Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        mobileMenuShow: state.mobileMenuShow,
        mainContent: state.mainContent,
        user: state.user,
    };
}

export default connect(mapStateToProps)(MobileMenu);

MobileMenu.propTypes = {
    mobileMenuShow: PropTypes.bool,
    mainContent: PropTypes.object,
    user: PropTypes.object,
};
