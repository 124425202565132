const pages = [
    {
        links: ['404'],
        name: '404',
        content: '404',
    },
    {
        links: ['', undefined],
        name: 'index',
        content: 'Главная',
    },
    {
        links: ['about'],
        name: 'about',
        content: 'О продукте',
    },
    {
        links: ['winners'],
        name: 'winners',
        content: 'Победители',
    },
    {
        links: ['faq'],
        name: 'faq',
        content: 'Faq',
    },
    {
        links: ['anket'],
        name: 'anket',
    },
    {
        links: ['full-anket'],
        name: 'fullAnket',
    },
    {
        links: ['prize-anket'],
        name: 'prizeAnket',
    },
    {
        links: ['act'],
        name: 'act',
    },
    {
        links: ['profile'],
        name: 'profile',
    },
    {
        links: ['', undefined],
        name: 'profile-codes',
        content: 'Коды',
        level: 1,
        parentName: 'profile',
    },
    // {
    //     links: ['sounds'],
    //     name: 'profile-sounds',
    //     content: 'Джинглы',
    //     level: 1,
    //     parentName: 'profile',
    // },
    {
        links: ['cheques'],
        name: 'profile-cheques',
        content: 'чеки',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['prizes'],
        name: 'profile-prizes',
        content: 'призы',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['login'],
        name: 'login',
        mainPage: 'index',
        isPopup: true,
    },
    {
        links: ['registration'],
        name: 'registration',
        mainPage: 'index',
        isPopup: true,
    },
    {
        links: ['cheques'],
        name: 'cheques',
        mainPage: (reduxStore) => (reduxStore?.user ? 'profile' : 'index'),
        isPopup: true,
    },
    {
        links: ['widget'],
        name: 'widget',
    },
    {
        links: ['chequeForm'],
        name: 'chequeForm',
    },
    {
        links: ['anketForm'],
        name: 'anketForm',
    },
    {
        links: ['share'],
        name: 'share',
        isPopup: true,
        mainPage: 'index',
    },
    {
        links: [],
        name: 'share-inner',
        parentName: 'share',
    },
];

export default pages;
