import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../functions/Slider';
import setSpacesInText from '../functions/setSpacesInText';
import Button from './Button.jsx';
import removeTransition from '../functions/removeTransition';

class ProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    handlerCard(isShow) {
        const { id } = this.props;
        clearTimeout(this.timerId);

        this.setState({ isShow });

        this.timerId = setTimeout(() => {
            removeTransition({
                item: `.productCard[data-id="${id}"] .productCard__content`,
                onlyChild: true,
            });
            this.setState({ isShowInfo: isShow });
        }, 150);
    }

    sliderInit() {
        const parent = this.parent.current;

        this.slider = new Slider({
            slider: parent,
            lay: parent.querySelector('.productCard__imagesInner'),
            items: parent.querySelectorAll('.productCard__imagesItem'),
            infinity: true,
            withResize: true,
            current: 0,
            buttons: {
                prev: parent.querySelector('.productCard__imagesArrow._prev'),
                next: parent.querySelector('.productCard__imagesArrow._next'),
            },
        });
    }

    componentDidMount() {
        this.sliderInit();
    }

    componentWillUnmount() {
        this.slider.destroy();
    }

    render() {
        const { isShow, isShowInfo } = this.state;
        const { items = [], description, id, scrollToShops, wheretobuy } = this.props;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`productCard ${isShow ? '_active' : ''} ${
                        isShowInfo ? '_showInfo' : ''
                    }`}
                    data-id={id}
                >
                    <div className="productCard__inner">
                        <div className="productCard__content">
                            <div className="productCard__images">
                                <div className="productCard__imagesArrow _prev">
                                    <div className="arrowBtn _green _prev">
                                        <img
                                            src={require('../media/icon-arrow-prev.svg').default}
                                            alt=""
                                            className="arrowBtn__icon"
                                        />
                                    </div>
                                </div>
                                <div className="productCard__imagesArrow _next">
                                    <div className="arrowBtn _green _next">
                                        <img
                                            src={require('../media/icon-arrow-next.svg').default}
                                            alt=""
                                            className="arrowBtn__icon"
                                        />
                                    </div>
                                </div>
                                <div className="productCard__imagesInner">
                                    {items.map((item, key) => (
                                        <div className="productCard__imagesItem" key={key}>
                                            <img
                                                src={item.thumb}
                                                alt=""
                                                className="productCard__imagesItemPreview"
                                            />
                                            <div className="productCard__imagesItemVolume">
                                                <div className="productCard__volume">
                                                    {item.title}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className="productCard__title"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(description.header.title),
                                }}
                            ></div>
                            <div
                                className="productCard__btn"
                                onClick={() => this.handlerCard(true)}
                            >
                                О продукте
                            </div>
                            {wheretobuy && (
                                <div className="productCard__buy">
                                    <Button
                                        className="_purple _mediumSize _wide"
                                        onClick={scrollToShops}
                                    >
                                        Купить
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="productCard__content _back">
                            <div
                                className="productCard__title _sub"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(description.header.title),
                                }}
                            ></div>
                            <p
                                className="productCard__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(description.header.description),
                                }}
                            ></p>
                            <div className="productCard__volumes">
                                {items.map((item, key) => (
                                    <div className="productCard__volume" key={key}>
                                        {item.title}
                                    </div>
                                ))}
                            </div>
                            <div
                                className="productCard__btn"
                                onClick={() => this.handlerCard(false)}
                            >
                                ПЕРЕВЕРНУТЬ
                            </div>
                            {wheretobuy && (
                                <div className="productCard__buy">
                                    <Button
                                        className="_purple _mediumSize _wide"
                                        onClick={scrollToShops}
                                    >
                                        Купить
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(ProductCard);

ProductCard.propTypes = {
    items: PropTypes.array,
    description: PropTypes.object,
    id: PropTypes.string,
    group: PropTypes.string,
    scrollToShops: PropTypes.func,
    wheretobuy: PropTypes.array,
};
