import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import Checkbox from './Checkbox.jsx';
import Button from './Button.jsx';
import Slider from '../functions/Slider';
import handlerPopup from '../functions/handlerPopup';
import handlerLoading from '../functions/handlerLoading';
import getHeaders from '../functions/getHeaders';
import checkAuth from '../functions/checkAuth';
import AnimateChange from './AnimateChange.jsx';
import requestSuccess from '../functions/requestSuccess';

class OrderPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.handlerCheckbox = this.handlerCheckbox.bind(this);
    }

    isPrivate = true;

    getBlocks() {
        const { currentBlock } = this.state;

        return [{ id: currentBlock }];
    }

    handlerCheckbox({ value }) {
        this.setState({ isConfirm: value });
    }

    sliderInit() {
        const { items } = this.state;

        const slider = this.parent.current?.querySelector('.popup__order');

        if (slider) {
            this.slider = new Slider({
                slider,
                lay: slider.querySelector('.popup__orderItems'),
                items: slider.querySelectorAll('.popup__orderItem'),
                infinity: true,
                withResize: true,
                current: 0,
                buttons: {
                    prev: slider.querySelector('.popup__orderArrow._prev'),
                    next: slider.querySelector('.popup__orderArrow._next'),
                },
                callback: ({ type, current }) => {
                    if (type === 'change') {
                        this.setState({ currentMerch: items[current].code });
                    }
                },
            });
        }
    }

    setCurrentBlock(currentBlock) {
        this.setState({
            currentBlock,
            ...(currentBlock === 'start'
                ? {
                      error: null,
                  }
                : {}),
        });
    }

    renderBlock({ prop: id }) {
        const { orderPopup } = this.props;
        const { header } = orderPopup;
        const { isConfirm, error, items = [], success, currentMerch, loadingKey } = this.state;
        const currentItem = items.find((item) => item.code === currentMerch);

        return (
            <div className="popup__block">
                {id === 'start' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">{header?.title}</div>
                            <p className="popup__headDescription">{header?.description}</p>
                        </div>
                        <div className="popup__content">
                            <div className="popup__order">
                                <div className="popup__orderContent">
                                    <div className="popup__orderArrow _prev">
                                        <div className="arrowBtn _green _prev">
                                            <img
                                                src={
                                                    require('../media/icon-arrow-prev.svg').default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="popup__orderArrow _next">
                                        <div className="arrowBtn _green _next">
                                            <img
                                                src={
                                                    require('../media/icon-arrow-next.svg').default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="popup__orderContentInner">
                                        <div className="popup__orderItems">
                                            {items.map((item) => (
                                                <div className="popup__orderItem" key={item.code}>
                                                    <img
                                                        src={item.thumb}
                                                        alt=""
                                                        className="popup__orderItemImage"
                                                    />
                                                    <p className="popup__orderItemName">
                                                        {item.title}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="popup__orderInfo">
                                        <AnimateChange
                                            className="popup__orderInfoInner"
                                            renderKey={currentMerch}
                                        >
                                            {currentItem?.price}
                                        </AnimateChange>
                                    </div>
                                </div>
                                <div className="popup__orderCheckbox">
                                    <Checkbox
                                        className="_bold"
                                        onChange={this.handlerCheckbox}
                                        checked={isConfirm}
                                    >
                                        Подтверждаю заказ
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                        <div className="popup__foot">
                            {this.renderError({ error })}
                            <div className="popup__buttons">
                                <div className="popup__button _mediumSize">
                                    <Button
                                        className="_red _2 _mediumSize _wide"
                                        onClick={() => {
                                            this.sendOrder();
                                        }}
                                        loader={loadingKey === 'send'}
                                    >
                                        заказать
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {id === 'success' && (
                    <>
                        <div className="popup__head">
                            <div className="popup__headTitle">{success?.title}</div>
                        </div>
                        <div className="popup__content">
                            <div className="popup__orderSuccess">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: success?.description.replace(/\n\r/gi, '<br/>'),
                                    }}
                                ></span>{' '}
                                {currentItem?.price}
                            </div>
                        </div>
                        <div className="popup__foot">
                            <div className="popup__buttons">
                                <div className="popup__button">
                                    <Button
                                        className="_emptyRed _2 _mediumSize _wide"
                                        onClick={() => {
                                            this.setCurrentBlock('start');
                                        }}
                                    >
                                        Заказать ещё
                                    </Button>
                                </div>
                                <div className="popup__button">
                                    <Button
                                        className="_red _2 _mediumSize _wide"
                                        onClick={() => {
                                            handlerPopup({ name: 'orderPopup', isShow: false });
                                        }}
                                    >
                                        Закрыть окно
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    sendOrder() {
        const { currentMerch, isConfirm } = this.state;

        if (!isConfirm) {
            this.setState({ error: 'Необходимо подтвердить заказ' });
        } else {
            handlerLoading.call(this, 'send', { error: null }).then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/api/AddUserPrize`,
                        { prize: currentMerch },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerLoading.call(this, null);

                            requestSuccess(res);

                            if (res.data?.result === 'OK') {
                                this.setCurrentBlock('success');

                                checkAuth();

                                document.dispatchEvent(new CustomEvent('updateProfile'));
                            }
                        },
                        (err) => {
                            handlerLoading.call(this, null);

                            this.setState({ error: err.response?.data?.errorText });
                        },
                    );
            });
        }
    }

    init() {
        const { orderPopup } = this.props;
        const { currentMerch, items = [], success } = orderPopup;
        const resultItems = JSON.parse(JSON.stringify(items)).sort((a, b) => {
            const aWeigth = a.code === currentMerch ? 1 : 0;
            const bWeigth = b.code === currentMerch ? 1 : 0;

            return bWeigth - aWeigth;
        });

        this.setState({ currentMerch, items: resultItems, success }, () => {
            handlerPopup({
                name: 'orderPopup',
                isShow: true,
                currentMerch: null,
                items: null,
                success: null,
            });
        });
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.slider.destroy();
    }

    render() {
        const { currentBlock, renderUpdateKey } = this.state;

        return (
            <>
                <Popup
                    name="orderPopup"
                    setRef={(parent) => {
                        this.parent = parent;
                    }}
                >
                    <ListAbsoluteMain
                        className="popup__blocks"
                        items={this.getBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="popup__block"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        currentItemKey={currentBlock}
                        allItems={['start', 'success']}
                        resizeParent={document.querySelector('.body')}
                        keyRender={renderUpdateKey}
                        callback={({ isInit }) => {
                            if (isInit) {
                                this.sliderInit();
                            }
                        }}
                    />
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        orderPopup: state.orderPopup,
    };
}

export default connect(mapStateToProps)(OrderPopup);

OrderPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    orderPopup: PropTypes.object,
};
