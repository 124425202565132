import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnketForm from './anket/Form.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';

class Anket extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'anket';

    contentName = 'main';

    render() {
        const { components } = this.state;
        const { user } = this.props;

        return (
            <>
                <div ref={this.parent} className="page _anket _inner">
                    <div className="page__section">
                        <div className="innerPageHead _outside">
                            <div className="innerPageHead__title">Анкета</div>
                            <p className="innerPageHead__description _mediumSize">
                                {user && user?.status !== 'ANKET_REQUIRED' ? (
                                    <>Вы можете изменить данные профиля ниже:</>
                                ) : (
                                    <>
                                        Для завершения регистрации, <br className="_desktopMedia" />
                                        заполните даннные ниже:
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="page__section">
                        <AnketForm components={components} />
                    </div>
                    <div className="page__section _footer">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Anket);

Anket.propTypes = {
    device: PropTypes.string,
};
