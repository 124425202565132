import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';
import setSpacesInText from '../functions/setSpacesInText';

class SoundsPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    render() {
        const { soundsPopup } = this.props;
        const { items = [] } = soundsPopup;

        return (
            <Popup name="soundsPopup">
                <div className="popup__head">
                    <div className="popup__headTitle">Накопленные звуки</div>
                </div>
                <div className="popup__content">
                    <div className="popup__table">
                        <div className="popup__tableRow _head">
                            <div className="popup__tableCol _date">Дата</div>
                            <div className="popup__tableCol _counter">Звуки</div>
                            <div className="popup__tableCol _comment">Комментарий</div>
                        </div>
                        <div className="popup__tableContent">
                            {items.map((item, key) => (
                                <div className="popup__tableRow" key={key}>
                                    <div className="popup__tableCol _date">{item.datetime}</div>
                                    <div
                                        className={`popup__tableCol _counter ${
                                            +item.sum < 0 ? '_negative' : ''
                                        }`}
                                    >
                                        {item.sum}
                                    </div>
                                    <div
                                        className="popup__tableCol _comment"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(item.comment || '–'),
                                        }}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    <div className="popup__buttons">
                        <div className="popup__button _wide">
                            <Button
                                className="_red _2 _bigSize"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'soundsPopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                ОК
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        soundsPopup: state.soundsPopup,
    };
}

export default connect(mapStateToProps)(SoundsPopup);

SoundsPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    soundsPopup: PropTypes.object,
};
