import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ProfileHeader from './profile/Header.jsx';
import ProfileContent from './profile/Content.jsx';
import ProfileCodes from './profile/Codes.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';
import getHeaders from '../functions/getHeaders';
import requestSuccess from '../functions/requestSuccess';
import handlerPopup from '../functions/handlerPopup';

class Profile extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPrivateContent = this.getPrivateContent.bind(this);

        this.parent = React.createRef();
    }

    isPrivate = true;

    pageName = 'profile';

    contentName = 'profile';

    getPrivateContent(e) {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetCabinetInfo`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    this.setState({ privateContent: res.data.data }, () => {
                        if (
                            this.state.privateContent.codes?.length > 0 &&
                            !localStorage.getItem('showReferalPopup') &&
                            e === true
                        ) {
                            handlerPopup({ name: 'referalPopup', isShow: true });

                            localStorage.setItem('showReferalPopup', true);
                        }
                    });
                },
                () => {},
            );
    }

    componentDidMount() {
        super.componentDidMount();

        this.getPrivateContent(true);

        document.addEventListener('updateProfile', this.getPrivateContent);
    }

    componentWillUnmount() {
        document.removeEventListener('updateProfile', this.getPrivateContent);
    }

    render() {
        const { privateContent, components } = this.state;

        return (
            <>
                <div ref={this.parent} className="page _profile _inner">
                    <div className="page__section">
                        <ProfileHeader privateContent={privateContent} />
                    </div>
                    <div className="page__section">
                        <ProfileContent components={components} privateContent={privateContent} />
                    </div>
                    <div className="page__section">
                        <ProfileCodes info={components?.buy} />
                    </div>

                    <div className="page__section _footer">
                        <Footer footer={components?.footer} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
};
