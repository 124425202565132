import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from '../components/Popup.jsx';

class Share extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.initWidget = this.initWidget.bind(this);

        this.parent = React.createRef();
    }

    initWidget() {
        const { levels } = this.props;
        const trackId = levels[1];

        if (trackId && window.DCAudioWiget) {
            const settings = {
                rootRef: '#audioWidget2',
                trackId,
            };

            window.DCAudioWiget.init(settings);
        }
    }

    componentDidMount() {
        this.initWidget();
    }

    render() {
        return (
            <>
                <Popup className="_share">
                    <div className="div" id="audioWidget2"></div>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Share);

Share.propTypes = {
    levels: PropTypes.array,
};
