import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import handlerLoading from '../../functions/handlerLoading';
import logout from '../../functions/logout';
import getEndText from '../../functions/getEndText';
import Link from '../../components/Link.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import handlerPopup from '../../functions/handlerPopup';
import Animate from '../../components/Animate.jsx';

class ProfileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.logout = this.logout.bind(this);

        this.parent = React.createRef();
    }

    logout() {
        handlerLoading.call(this, 'logout').then(() => {
            logout().then(
                () => {
                    handlerLoading.call(this, null);
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { user, privateContent, device } = this.props;
        const names = [];

        if (user) {
            if (user.personal.firstName) {
                names.push(user.personal.firstName);
            }

            if (user.personal.lastName) {
                names.push(user.personal.lastName);
            }
        }

        const balance = privateContent?.balance || 0;

        return (
            <>
                <div ref={this.parent} className="profileHeader">
                    <div className="profileHeader__inner">
                        <div className="profileHeader__head">
                            <div className="innerPageHead">
                                <div className="innerPageHead__title">Личный кабинет</div>
                            </div>
                        </div>
                        <div className="profileHeader__content">
                            <div className="profileHeader__contentInner">
                                <div className="profileHeader__contentName">{names.join(' ')}</div>
                                <div className="profileHeader__contentBlocks">
                                    <div className="profileHeader__contentBlock">
                                        <div className="profileHeader__contentInfo _id">
                                            ID {user?.userId}
                                        </div>
                                        <Link
                                            href={
                                                user?.status === 'EXTRA_ANKET_REQUIRED'
                                                    ? 'full-anket'
                                                    : 'anket'
                                            }
                                            className="profileHeader__contentLink _show"
                                        >
                                            Изменить данные
                                        </Link>
                                    </div>
                                    <div className="profileHeader__contentBlock">
                                        <div className="profileHeader__contentInfo _sounds">
                                            У тебя{' '}
                                            <AnimateChange
                                                className="profileHeader__contentInfoCounter"
                                                renderKey={balance}
                                            >
                                                <>
                                                    {balance}{' '}
                                                    {getEndText(balance, [
                                                        'звук',
                                                        'звука',
                                                        'звуков',
                                                    ])}
                                                </>
                                            </AnimateChange>{' '}
                                        </div>
                                        <Animate
                                            className="profileHeader__contentLink"
                                            onClick={() => {
                                                handlerPopup({
                                                    name: 'soundsPopup',
                                                    isShow: true,
                                                    items: privateContent?.transactions,
                                                });
                                            }}
                                            isShow={privateContent?.transactions?.length > 0}
                                        >
                                            История
                                        </Animate>
                                    </div>

                                    <AnimateChange
                                        className="profileHeader__contentBlock _dynamic"
                                        renderKey={
                                            privateContent?.codes?.length > 0 ? true : undefined
                                        }
                                        parentStyles={['height']}
                                    >
                                        <div
                                            className="profileHeader__contentInfo _referal"
                                            onClick={() => {
                                                handlerPopup({
                                                    name: 'referalPopup',
                                                    isShow: true,
                                                });
                                            }}
                                        >
                                            Пригласи друга {device === 'mobile' && <br />}- получи
                                            звук
                                        </div>
                                    </AnimateChange>
                                </div>
                                <div className="profileHeader__contentLogout">
                                    <Button
                                        className="_red _2"
                                        onClick={this.logout}
                                        loader={loadingKey === 'logout'}
                                    >
                                        Выход
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileHeader);

ProfileHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
};
