import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';
import changePage from '../../../functions/changePage';

class ProfileContentPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkPrizes() {
        const { parent, privateContent, setUpdateKey } = this.props;

        if (privateContent && !this.isReady) {
            this.isReady = true;

            this.setState(
                {
                    prizes: privateContent.prizes,
                },
                () => {
                    setUpdateKey();

                    const prizes = parent.current.querySelector('.profileContent__prizes');

                    this.setState(
                        {
                            prizesHeight: prizes.offsetHeight,
                        },
                        () => {
                            setUpdateKey();
                        },
                    );

                    setTimeout(() => {
                        this.setState(
                            {
                                isCompleteReady: true,
                            },
                            () => {
                                setUpdateKey();
                            },
                        );
                    }, 300);
                },
            );
        }
    }

    renderPrize({ id, count, thumb, title, status, statusTitle, url }) {
        const StatusTag = url ? 'a' : 'div';
        const statusProps = url ? { href: url, target: '_blank' } : {};

        if (status === 'DATA_NEEDED') {
            statusProps.onClick = () => {
                changePage({ href: 'full-anket' });
            };
        }

        return (
            <div className="profileContent__prizeWrapper">
                <div className={`profileContent__prize _${status}`} key={id}>
                    <img src={thumb} alt="" className="profileContent__prizeImage" />
                    <div
                        className="profileContent__prizeName"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                    ></div>
                    {count > 0 && (
                        <div className="profileContent__prizeCounter">
                            {count}
                            <br />
                            <span>шт.</span>
                        </div>
                    )}
                    <div className="profileContent__prizeStatusWrapper">
                        <StatusTag
                            className={`profileContent__prizeStatus _${status}`}
                            {...statusProps}
                        >
                            {statusTitle}
                        </StatusTag>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.checkPrizes();
    }

    componentDidUpdate() {
        this.checkPrizes();
    }

    render() {
        const { prizes, isCompleteReady, prizesHeight } = this.state;

        return (
            <>
                <div className="profileContent__pageHead">
                    <div className="profileContent__pageTitle">Мои призы</div>
                </div>
                <div className={`profileContent__pageContent ${prizes ? '_ready' : ''}`}>
                    <Animate className="profileContent__pageLoader" isShow={!prizes}>
                        <div className="profileContent__pageLoaderItem">
                            <Loader />
                        </div>
                    </Animate>
                    <div
                        className="profileContent__prizesWrapper"
                        style={
                            !prizes
                                ? { height: '40rem' }
                                : !isCompleteReady
                                ? { height: `${prizesHeight}px` }
                                : {}
                        }
                    >
                        <div className="profileContent__prizes">
                            {(prizes || []).map((prize) => this.renderPrize(prize))}
                            {prizes?.length === 0 && (
                                <>
                                    <div className="profileContent__prizesInfo">
                                        <div className="profileContent__prizesInfoTitle">
                                            Призов пока нет
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ProfileContentPrizes);

ProfileContentPrizes.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    privateContent: PropTypes.object,
    prizes: PropTypes.array,
    setUpdateKey: PropTypes.func,
    parent: PropTypes.object,
};
