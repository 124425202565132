import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProductCard from '../../components/ProductCard.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';
import scrollToPosition from '../../functions/scrollToPosition';

class AboutHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.scrollToShops = this.scrollToShops.bind(this);

        this.parent = React.createRef();
    }

    scrollToShops() {
        const topBar = document.querySelector('.topBar');
        let offset = 0;

        if (topBar) {
            offset = topBar.offsetHeight;
        }

        scrollToPosition({
            position: 'center',
            parent: this.parent.current.closest('.body__page'),
            classNameElem: '.aboutShops',
            offset,
            duration: 300,
        });
    }

    checkReady() {
        const { products } = this.props;

        if (products && !this.isReady) {
            this.isReady = true;

            const inner = this.parent.current.querySelector('.about__contentInner');

            this.setState({ contentHeight: inner.offsetHeight, isReady: true });

            setTimeout(() => {
                this.setState({ contentHeight: null });
            }, 500);
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { isReady, contentHeight } = this.state;
        const { products = [], wheretobuy } = this.props;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`about ${isReady ? '_ready' : ''} ${!wheretobuy ? '_last' : ''}`}
                >
                    <div className="about__inner">
                        <div className="about__head">
                            <div className="innerPageHead">
                                <h1 className="innerPageHead__title">О продукте</h1>
                            </div>
                        </div>
                        <div
                            className="about__content"
                            style={
                                !isReady
                                    ? { height: `60rem` }
                                    : contentHeight
                                    ? { height: `${contentHeight}px` }
                                    : {}
                            }
                        >
                            <Animate className="about__loader" isShow={!isReady}>
                                <div className="about__loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="about__contentInner">
                                <div className="about__block">
                                    <div className="about__blockContent">
                                        {products.map((product, productKey) => (
                                            <div className="about__blockCard" key={productKey}>
                                                <ProductCard
                                                    {...product}
                                                    items={product.sku || product.drinks}
                                                    group="main"
                                                    id={`product-${productKey}`}
                                                    scrollToShops={this.scrollToShops}
                                                    wheretobuy={wheretobuy}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(AboutHeader);

AboutHeader.propTypes = {
    device: PropTypes.string,
    products: PropTypes.array,
    wheretobuy: PropTypes.array,
};
