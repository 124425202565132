import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';
import AnimateChange from './AnimateChange.jsx';
import copyInBuffer from '../functions/copyInBuffer';

class ReferalPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    isPrivate = true;

    copy() {
        copyInBuffer(this.getLink.call(this)).then(() => {
            this.setState({ isCopy: true });
        });
    }

    getLink() {
        const { user } = this.props;

        return `https://dobrycola-promo.ru/?inv=${user?.userId}`;
    }

    render() {
        const { isCopy } = this.state;
        const { user } = this.props;

        return (
            <Popup name="referalPopup">
                <div className="popup__head">
                    <div className="popup__headTitle">пригласи друга</div>
                </div>
                <div className="popup__content">
                    <div className="popup__referal">
                        <div className="popup__referalText">
                            Пригласи друга для участия в акции!
                            <br />
                            Вы&nbsp;оба получите дополнительные звуки, после того, как друг
                            зарегистрирует первый код. Количество друзей не ограничено.
                        </div>
                        <div className="popup__referalText _link">
                            Ссылка для приглашения:{' '}
                            <a target="_blank" href={this.getLink.call(this)} rel="noreferrer">
                                {this.getLink.call(this)}
                            </a>
                        </div>
                        <div className="popup__referalText">
                            Промокод для друга: <span>{user?.userId}</span>
                        </div>
                        <AnimateChange
                            className="popup__referalCopy _center"
                            renderKey={isCopy ? true : undefined}
                        >
                            <div className="popup__referalCopyInner">
                                Скопировано в буфер обмена
                            </div>
                        </AnimateChange>
                    </div>
                </div>
                <div className="popup__foot">
                    <AnimateChange className="popup__buttons _center" renderKey={!!isCopy}>
                        {isCopy ? (
                            <>
                                <div className="popup__button _wide">
                                    <Button
                                        className="_red _2 _mediumSize"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'referalPopup',
                                                isShow: false,
                                            });
                                        }}
                                    >
                                        Закрыть
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="popup__button _wide">
                                    <Button
                                        className="_red _2 _mediumSize"
                                        onClick={() => {
                                            this.copy.call(this);
                                        }}
                                    >
                                        Копировать
                                    </Button>
                                </div>
                            </>
                        )}
                    </AnimateChange>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ReferalPopup);

ReferalPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
