import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../functions/setSpacesInText';
import Slider from '../../functions/Slider';

class IndexPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: {},
        };

        this.parent = React.createRef();
    }

    setLoop() {
        const { components } = this.props;
        const merchContent = components?.merch;

        this.intervalId = setInterval(() => {
            this.setState((state) => {
                const newState = { ...state };
                const images = { ...newState.images };

                merchContent.prizes.forEach((prize, key) => {
                    const thumbs = prize.thumbs || [prize.thumb];

                    images[key] = images[key] === thumbs.length - 1 ? 0 : images[key] + 1;
                });

                newState.images = images;

                return newState;
            });
        }, 2_000);
    }

    sliders = {};

    sliderInit() {
        const { components } = this.props;
        const merchContent = components?.merch;
        const images = {};

        merchContent.prizes.forEach((prize, key) => {
            images[key] = 0;
        });

        this.setState({ images }, () => {
            this.setLoop();
        });

        this.parent.current.querySelectorAll('.indexPrizes__content').forEach((slider, key) => {
            this.sliders[key] = new Slider({
                slider,
                lay: slider.querySelector('.indexPrizes__cards'),
                items: slider.querySelectorAll('.indexPrizes__card'),
                infinity: false,
                withResize: true,
                current: 0,
                buttons: {
                    prev: slider.querySelector('.indexPrizes__arrow._prev'),
                    next: slider.querySelector('.indexPrizes__arrow._next'),
                },
                type: 'mobile',
            });
        });
    }

    checkInit() {
        const { components } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;

            this.sliderInit();
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);

        Object.keys(this.sliders).forEach((key) => {
            this.sliders[key].destroy();
        });
    }

    render() {
        const { images } = this.state;
        const { components } = this.props;
        const merchContent = components?.merch;
        const content = components?.raffle2;

        return (
            <>
                <div ref={this.parent} className={`indexPrizes ${content ? '_ready' : ''}`}>
                    <div className="indexPrizes__decor _1"></div>
                    <div className="indexPrizes__decor _2"></div>
                    <div className="indexPrizes__decor _3"></div>

                    <div className="indexPrizes__inner">
                        <div className="indexPrizes__head _merch">
                            <div className="indexPrizes__headTitle">
                                {merchContent?.info?.header?.title}
                            </div>
                            <p className="indexPrizes__headSubTitle">
                                {merchContent?.info?.header?.description}
                            </p>
                        </div>
                        <div className="indexPrizes__content">
                            <div className="indexPrizes__arrow _prev">
                                <div className="arrowBtn _prev">
                                    <img
                                        src={require('../../media/icon-arrow-prev.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexPrizes__arrow _next">
                                <div className="arrowBtn _next">
                                    <img
                                        src={require('../../media/icon-arrow-next.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexPrizes__cards">
                                {merchContent?.prizes?.map((prize, key) => {
                                    const thumbs = prize.thumbs || [prize.thumb];

                                    return (
                                        <div
                                            className={`indexPrizes__card _slider ${
                                                [3, 4].includes(key) ? '_mobMargin' : ''
                                            }`}
                                            key={key}
                                        >
                                            <div className="indexPrizes__cardInner">
                                                <div className="indexPrizes__cardHead">
                                                    {thumbs.map((thumb, thumbKey) => (
                                                        <img
                                                            src={thumb}
                                                            alt=""
                                                            className={`indexPrizes__cardImage ${
                                                                images[key] === thumbKey
                                                                    ? '_current'
                                                                    : ''
                                                            }`}
                                                            key={thumbKey}
                                                            data-key={thumbKey}
                                                        />
                                                    ))}
                                                </div>
                                                <div
                                                    className="indexPrizes__cardName"
                                                    dangerouslySetInnerHTML={{
                                                        __html: setSpacesInText(prize.title),
                                                    }}
                                                ></div>
                                                <div className="indexPrizes__cardInfo">
                                                    <div className="indexPrizes__support">
                                                        {prize.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="indexPrizes__head _week">
                            <div className="indexPrizes__headTitle">
                                {content?.info?.header?.title}
                            </div>
                            <div className="indexPrizes__headDate">
                                {content?.info?.header?.description}{' '}
                                {content?.info?.header?.raffleDate}
                            </div>
                        </div>
                        <div className="indexPrizes__content">
                            <div className="indexPrizes__arrow _prev">
                                <div className="arrowBtn _prev">
                                    <img
                                        src={require('../../media/icon-arrow-prev.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexPrizes__arrow _next">
                                <div className="arrowBtn _next">
                                    <img
                                        src={require('../../media/icon-arrow-next.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexPrizes__cards _week">
                                {content?.prizes?.map((prize, key) => (
                                    <div className="indexPrizes__card _full" key={key}>
                                        <div className="indexPrizes__cardInner">
                                            <div className="indexPrizes__cardHead">
                                                <img
                                                    src={prize.thumb}
                                                    alt=""
                                                    className="indexPrizes__cardImage"
                                                />
                                            </div>
                                            <div className="indexPrizes__cardName">
                                                {prize.title}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="indexPrizes__info">
                            <div className="indexPrizes__infoSupport">
                                <div className="indexPrizes__support">
                                    {content?.info?.price?.title}
                                </div>
                            </div>
                            <p
                                className="indexPrizes__infoDescription"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(content?.info?.price?.description),
                                }}
                            ></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(IndexPrizes);

IndexPrizes.propTypes = {
    device: PropTypes.string,
    components: PropTypes.object,
};
