import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from '../../components/Link.jsx';
import Slider from '../../functions/Slider';

class IndexMomentPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    sliderInit() {
        const slider = this.parent.current;

        this.slider = new Slider({
            id: 'moment',
            slider,
            lay: slider.querySelector('.indexMomentPrizes__cards'),
            items: slider.querySelectorAll('.indexMomentPrizes__card'),
            infinity: false,
            withResize: true,
            current: 0,
            buttons: {
                prev: slider.querySelector('.indexMomentPrizes__arrow._prev'),
                next: slider.querySelector('.indexMomentPrizes__arrow._next'),
            },
            type: 'mobile',
        });
    }

    checkInit() {
        const { components } = this.props;
        const content = components?.raffle1;

        if (content && !this.isInit) {
            this.isInit = true;

            this.sliderInit();
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    render() {
        const { components, user } = this.props;
        const content = components?.raffle1;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexMomentPrizes ${content ? '_ready' : ''}`}
                    id="prizes"
                >
                    <div className="indexMomentPrizes__inner">
                        <div className="indexMomentPrizes__head">
                            <Link
                                className="indexMomentPrizes__title _click"
                                href={user ? 'profile' : 'login'}
                                callback={() => {
                                    if (user) {
                                        document.dispatchEvent(
                                            new CustomEvent('scrollToCodes', { detail: {} }),
                                        );
                                    }
                                }}
                            >
                                Моментальный <br className="_mobileMedia" />
                                розыгрыш
                            </Link>
                            <div className="indexMomentPrizes__subTitle">
                                {content?.info?.price?.title}
                            </div>
                            <p className="indexMomentPrizes__description">
                                {content?.info?.price?.description}
                            </p>
                        </div>
                        <div className="indexMomentPrizes__content">
                            <div className="indexMomentPrizes__arrow _prev">
                                <div className="arrowBtn _white _prev">
                                    <img
                                        src={require('../../media/icon-arrow-prev.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexMomentPrizes__arrow _next">
                                <div className="arrowBtn _white _next">
                                    <img
                                        src={require('../../media/icon-arrow-next.svg').default}
                                        alt=""
                                        className="arrowBtn__icon"
                                    />
                                </div>
                            </div>
                            <div className="indexMomentPrizes__cards">
                                {content?.prizes?.map((prize, key) => (
                                    <div className="indexMomentPrizes__card" key={key}>
                                        <img
                                            src={prize.thumb}
                                            alt=""
                                            className="indexMomentPrizes__cardImage"
                                        />
                                        <p className="indexMomentPrizes__cardDescription">
                                            {prize.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexMomentPrizes);

IndexMomentPrizes.propTypes = {
    device: PropTypes.string,
    components: PropTypes.object,
    user: PropTypes.object,
};
