import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnketForm from './prizeAnket/Form.jsx';

import Footer from '../components/Footer.jsx';
import Page from '../components/Page.jsx';

class PrizeAnket extends Page {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pageName = 'prizeAnket';

    render() {
        return (
            <>
                <div ref={this.parent} className="page _prizeAnket _inner">
                    <div className="page__section">
                        <div className="innerPageHead _outside">
                            <div className="innerPageHead__title">Анкета</div>
                            <p className="innerPageHead__description _mediumSize">
                                Для получения приза, пожалуйста, заполните все поля{' '}
                                <br className="_desktopMedia" />и приложите необходимые документы:
                            </p>
                        </div>
                    </div>
                    <div className="page__section">
                        <AnketForm />
                    </div>
                    <div className="page__section _footer">
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PrizeAnket);

PrizeAnket.propTypes = {
    device: PropTypes.string,
};
