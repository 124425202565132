import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../../functions/Slider';
import setSpacesInText from '../../functions/setSpacesInText';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import changePage from '../../functions/changePage';

class IndexInstruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeActivity = this.changeActivity.bind(this);

        this.parent = React.createRef();
    }

    sliderInit() {
        const { components, device } = this.props;
        const content = components?.howto;
        const parent = this.parent.current;
        const duration = content?.['header-2']?.animation
            ? +content?.['header-2']?.animation * 1000
            : 3_000;

        const callback = ({ type }) => {
            if (['change', 'reset'].includes(type)) {
                this.parent.current
                    .querySelectorAll('.indexInstruction__sliderCard')
                    .forEach((card) => {
                        card.classList.remove('_show');
                    });

                const currentCard = this.parent.current.querySelector(
                    `.indexInstruction__sliderCard[data-current]`,
                );

                if (currentCard) {
                    if (currentCard.previousElementSibling) {
                        currentCard.previousElementSibling.classList.add('_show');
                    }

                    if (currentCard.nextElementSibling) {
                        currentCard.nextElementSibling.classList.add('_show');
                    }
                }
            }
        };

        const current = device === 'mobile' ? 0 : 1;

        this.slider = new Slider({
            slider: parent,
            lay: parent.querySelector('.indexInstruction__sliderInner'),
            items: parent.querySelectorAll('.indexInstruction__sliderCard'),
            infinity: true,
            withResize: true,
            current,
            buttons: {
                prev: parent.querySelector('.indexInstruction__sliderArrow._prev'),
                next: parent.querySelector('.indexInstruction__sliderArrow._next'),
            },
            pagenation: {
                parent: parent.querySelector('.indexInstruction__sliderPagination'),
                dot: 'indexInstruction__sliderPaginationItem',
            },
            callback,
            loop: duration,
        });
    }

    checkInit() {
        const { components, loades } = this.props;
        const content = components?.howto;

        if (content && !this.isInit && loades[8]) {
            this.isInit = true;

            this.sliderInit();

            this.linksInit();
        }
    }

    linksInit() {
        const { user } = this.props;

        this.parent.current.querySelectorAll('.indexInstruction__card').forEach((card) => {
            const link = card.querySelector('a');

            if (link) {
                const href = link.getAttribute('href').replace(/\//gi, '');

                if (href === 'profile') {
                    link.onclick = (e) => {
                        e.preventDefault();

                        changePage({ href: user ? 'profile' : 'login' });
                    };
                }
            }
        });
    }

    changeActivity({ detail: { active } }) {
        if (this.slider) {
            if (active) {
                this.slider.setLoop();
            } else {
                this.slider.stopLoop();
            }
        }
    }

    componentDidMount() {
        this.checkInit();

        document.addEventListener('changeActivity', this.changeActivity);
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        if (this.slider) {
            this.slider.destroy();
        }

        document.removeEventListener('changeActivity', this.changeActivity);
    }

    render() {
        const { components, user, loades } = this.props;
        const content = components?.howto;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexInstruction ${content ? '_ready' : ''}`}
                    id="rules"
                >
                    <div className="indexInstruction__inner">
                        <div className="indexInstruction__head">
                            <div className="indexInstruction__headTitle">
                                {content?.header?.title}
                            </div>
                            <p className="indexInstruction__headDescription">
                                {content?.period?.title}
                            </p>
                        </div>
                        {loades[6] && (
                            <div className="indexInstruction__cards">
                                <div className="indexInstruction__card">
                                    <div className="indexInstruction__cardInner">
                                        <img
                                            src={require('../../media/steps-image-01.png')}
                                            alt=""
                                            className="indexInstruction__cardDecor"
                                        />
                                        <div className="indexInstruction__cardBottles">
                                            <div className="indexInstruction__cardBottle _1">
                                                <img
                                                    src={require('../../media/animate/step1-anime-2.webp')}
                                                    alt=""
                                                    className="indexInstruction__cardBottleImage"
                                                />
                                            </div>
                                            <div className="indexInstruction__cardBottle _2">
                                                <img
                                                    src={require('../../media/animate/step1-anime-3.webp')}
                                                    alt=""
                                                    className="indexInstruction__cardBottleImage"
                                                />
                                            </div>
                                            <div className="indexInstruction__cardBottle _3">
                                                <img
                                                    src={require('../../media/animate/step1-anime-4.webp')}
                                                    alt=""
                                                    className="indexInstruction__cardBottleImage"
                                                />
                                            </div>
                                        </div>
                                        <Link
                                            className="indexInstruction__cardTitle _link"
                                            href="about"
                                        >
                                            <span>{content?.step1?.title}</span>
                                        </Link>
                                        <p
                                            className="indexInstruction__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step1?.description,
                                                ),
                                            }}
                                        ></p>
                                        <p
                                            className="indexInstruction__cardSupport"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.tip1?.description ||
                                                        content?.tip1?.title,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="indexInstruction__card">
                                    <div className="indexInstruction__cardInner">
                                        <img
                                            src={require('../../media/steps-image-03.png')}
                                            alt=""
                                            className="indexInstruction__cardDecor"
                                        />
                                        <div className="indexInstruction__cardTitle">
                                            {content?.step2?.title}
                                        </div>
                                        <p
                                            className="indexInstruction__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step2?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="indexInstruction__card">
                                    <div className="indexInstruction__cardInner">
                                        <img
                                            src={require('../../media/steps-image-04.png')}
                                            alt=""
                                            className="indexInstruction__cardImage"
                                        />
                                        <div className="indexInstruction__cardTitle">
                                            {content?.step3?.title}
                                        </div>
                                        <p
                                            className="indexInstruction__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step3?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="indexInstruction__card">
                                    <div className="indexInstruction__cardInner">
                                        <img
                                            src={require('../../media/steps-image-05.png')}
                                            alt=""
                                            className="indexInstruction__cardImage"
                                        />
                                        <div className="indexInstruction__cardTitle">
                                            {content?.step4?.title}
                                        </div>
                                        <p
                                            className="indexInstruction__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step4?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="indexInstruction__card">
                                    <div className="indexInstruction__cardInner">
                                        <img
                                            src={require('../../media/steps-image-06.png')}
                                            alt=""
                                            className="indexInstruction__cardDecor"
                                        />
                                        <div className="indexInstruction__cardTitle">
                                            {content?.step5?.title}
                                        </div>
                                        <p
                                            className="indexInstruction__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step5?.description,
                                                ),
                                            }}
                                        ></p>
                                        <p
                                            className="indexInstruction__cardSupport"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    content?.tip2?.description ||
                                                    content?.tip2?.title,
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {loades[7] && (
                            <>
                                <div className="indexInstruction__head _2">
                                    <div className="indexInstruction__headTitle">
                                        Получай <span>дополнительные</span> звуки
                                    </div>
                                    <p
                                        className="indexInstruction__headDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(
                                                content?.['header-2']?.subtitle,
                                            ),
                                        }}
                                    ></p>
                                </div>
                                <div className="indexInstruction__slider">
                                    {loades[9] && (
                                        <div className="indexInstruction__sliderDecor _1"></div>
                                    )}
                                    {loades[10] && (
                                        <div className="indexInstruction__sliderDecor _2"></div>
                                    )}

                                    <div className="indexInstruction__sliderArrow _prev">
                                        <div className="arrowBtn _prev">
                                            <img
                                                src={
                                                    require('../../media/icon-arrow-prev.svg')
                                                        .default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="indexInstruction__sliderInner">
                                        {content?.['header-2']?.thumbsExtra?.map((item, key) => (
                                            <div className="indexInstruction__sliderCard" key={key}>
                                                <img
                                                    src={item[0]}
                                                    alt=""
                                                    className="indexInstruction__sliderCardImage"
                                                />
                                                <div className="indexInstruction__sliderCardValue">
                                                    {item[1]}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="indexInstruction__sliderArrow _next">
                                        <div className="arrowBtn _next">
                                            <img
                                                src={
                                                    require('../../media/icon-arrow-next.svg')
                                                        .default
                                                }
                                                alt=""
                                                className="arrowBtn__icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="indexInstruction__sliderPagination"></div>
                                </div>
                                <div
                                    className="indexInstruction__subDescription"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(content?.['header-2']?.description),
                                    }}
                                ></div>
                                <Link
                                    className="indexInstruction__button"
                                    href={user ? 'cheques' : 'login'}
                                >
                                    <div className="indexInstruction__buttonInfo">+ 1 звук</div>
                                    <Button className="_bigSize _white _2">регистрируй чек</Button>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        loades: state.loades,
    };
}

export default connect(mapStateToProps)(IndexInstruction);

IndexInstruction.propTypes = {
    device: PropTypes.string,
    components: PropTypes.object,
    user: PropTypes.object,
    loades: PropTypes.array,
};
