import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Popup from './Popup.jsx';
import Button from './Button.jsx';
import AnimateChange from './AnimateChange.jsx';

import handlerPopup from '../functions/handlerPopup';
import checkAuth from '../functions/checkAuth';
import getHeaders from '../functions/getHeaders';
import Animate from './Animate.jsx';
import Loader from './Loader.jsx';
import handlerLoading from '../functions/handlerLoading';
import requestSuccess from '../functions/requestSuccess';

class GamePopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};
    }

    isPrivate = true;

    setGame({ play = false }) {
        return new Promise((resolve, reject) => {
            handlerLoading.call(this, 'game').then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/api/PlayGame`,
                        { play },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerLoading.call(this, null);

                            requestSuccess(res);

                            const { prizeList, prize } = res.data.data;

                            this.setState(
                                {
                                    prizeList,
                                    prize,
                                    isReady: true,
                                    ...(play ? { isStart: true } : {}),
                                },
                                () => {
                                    if (play) {
                                        checkAuth();

                                        document.dispatchEvent(new CustomEvent('updateProfile'));
                                    }

                                    this.getBalance();

                                    resolve();
                                },
                            );
                        },
                        (err) => {
                            handlerLoading.call(this, null);

                            this.setState({ error: err.response?.data?.errorText });

                            reject();
                        },
                    );
            });
        });
    }

    getBalance() {
        axios
            .get(`${process.env.REACT_APP_API}/api/GetCabinetInfo`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    this.setState({ balance: res.data.data?.balance });
                },
                () => {},
            );
    }

    isPlay = false;

    startGame() {
        const { prizeList = [], isReady } = this.state;

        if (isReady && !this.isPlay) {
            this.isPlay = true;

            this.setGame({ play: true }).then(
                () => {
                    let counter = 0;
                    const step = 300;

                    let intervalId;

                    const setLoop = () => {
                        const items = JSON.parse(JSON.stringify(prizeList)).map(
                            (item, key) => key + 1,
                        );
                        let innerIntervalId;

                        const setInnerLoop = () => {
                            if (items.length) {
                                let currentItemIndex = Math.round(
                                    Math.random() * (items.length - 1),
                                );

                                if (this.state.currentItemIndex) {
                                    while (
                                        items.indexOf(this.state.currentItemIndex) !== -1 &&
                                        items.indexOf(this.state.currentItemIndex) ===
                                            currentItemIndex
                                    ) {
                                        currentItemIndex = Math.round(
                                            Math.random() * (items.length - 1),
                                        );
                                    }
                                }

                                const currentItem = items[currentItemIndex];

                                items.splice(currentItemIndex, 1);

                                this.setState({ currentItemIndex: currentItem });
                            } else {
                                counter += 1;

                                clearInterval(innerIntervalId);
                            }

                            if (counter >= 2) {
                                clearInterval(intervalId);
                                clearInterval(innerIntervalId);

                                this.setState({ currentItemIndex: 1, isComplete: true });

                                this.isPlay = false;
                            }
                        };

                        setInnerLoop();

                        innerIntervalId = setInterval(() => {
                            setInnerLoop();
                        }, step);
                    };

                    setLoop();

                    intervalId = setInterval(() => {
                        setLoop();
                    }, step * 4);
                },
                () => {},
            );
        }
    }

    reset() {
        this.setState({ isComplete: false, play: false, isStart: false }, () => {
            setTimeout(() => {
                this.getBalance();

                this.setState({ currentItemIndex: null });
            }, 300);
        });
    }

    componentDidMount() {
        this.setGame({});
    }

    render() {
        const {
            error,
            isStart,
            isComplete,
            currentItemIndex,
            prizeList = [],
            isReady,
            prize: currentPrize,
            balance,
        } = this.state;

        return (
            <>
                <Popup
                    name="gamePopup"
                    setRef={(parent) => {
                        this.parent = parent;
                    }}
                >
                    <div className="popup__head">
                        <div className="popup__headTitle">Мгновенный розыгрыш</div>
                        <p className="popup__headDescription">Стоимость участия – 1 звук</p>
                    </div>
                    <div className="popup__content">
                        <div
                            className={`popup__game ${isReady ? '_ready' : ''} ${
                                isStart ? '_start' : ''
                            } ${isComplete ? '_complete' : ''}`}
                        >
                            <Animate className="popup__gameLoader" isShow={!isReady}>
                                <div className="popup__gameLoaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="popup__gameButtonWrapper">
                                <div
                                    className="popup__gameButton"
                                    onClick={() => {
                                        this.startGame();
                                    }}
                                >
                                    Нажмите, чтобы участвовать в&nbsp;мгновенном розыгрыше
                                </div>
                            </div>
                            <div className="popup__gamePrize">
                                <div
                                    className={`popup__gamePrizeSupport ${
                                        currentPrize ? '_show' : ''
                                    }`}
                                >
                                    Твой приз
                                </div>
                                {currentPrize ? (
                                    <img
                                        src={currentPrize.image}
                                        alt=""
                                        className={`popup__gamePrizeItem ${
                                            isComplete ? '_current' : ''
                                        }`}
                                    />
                                ) : (
                                    <img
                                        src={require('../media/prize-no.svg').default}
                                        alt=""
                                        className={`popup__gamePrizeItem ${
                                            isComplete ? '_current' : ''
                                        }`}
                                    />
                                )}

                                {prizeList.map((prize, key) => (
                                    <img
                                        src={prize.thumb}
                                        alt=""
                                        className={`popup__gamePrizeItem ${
                                            key + 1 === currentItemIndex && !isComplete
                                                ? '_current'
                                                : ''
                                        } _${key + 1}`}
                                        key={key}
                                    />
                                ))}
                                {[1, 2, 3, 4, 5].map((decorKey) => (
                                    <img
                                        src={require(`../media/game-decor-${decorKey}.svg`)}
                                        alt=""
                                        className={`popup__gamePrizeDecor _${decorKey}`}
                                        key={decorKey}
                                    />
                                ))}
                                <div className="popup__gamePrizeName">
                                    {currentPrize?.name || (
                                        <>
                                            К сожалению, <br />
                                            ты&nbsp;не выиграл
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup__foot">
                        {this.renderError({ error })}

                        <AnimateChange
                            className="popup__buttons _dynamic"
                            renderKey={isComplete ? 'complete' : 'start'}
                        >
                            {isComplete ? (
                                <>
                                    <div className="popup__button">
                                        {balance > 0 ? (
                                            <Button
                                                className="_red _2 _mediumSize _wide"
                                                onClick={() => {
                                                    this.reset();
                                                }}
                                            >
                                                Попробуй ещё
                                            </Button>
                                        ) : (
                                            <Button
                                                className="_red _2 _mediumSize _wide"
                                                onClick={() => {
                                                    handlerPopup({
                                                        name: 'gamePopup',
                                                        isShow: false,
                                                    });
                                                }}
                                            >
                                                спасибо
                                            </Button>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="popup__button">
                                        <Button
                                            className="_red _2 _mediumSize _wide"
                                            onClick={() => {
                                                handlerPopup({ name: 'gamePopup', isShow: false });
                                            }}
                                        >
                                            Сыграть позже
                                        </Button>
                                    </div>
                                </>
                            )}
                        </AnimateChange>
                    </div>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(GamePopup);

GamePopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
